export enum CoveragesTypes {
  GET_COVERAGES = `@coverages/GET_COVERAGES`,
  SET_COVERAGES = `@coverages/SET_COVERAGES`,
  GET_COVERAGES_REQUEST = `@coverages/GET_COVERAGES_REQUEST`,
  GET_SIMULATION_COVERAGES = `@coverages/GET_SIMULATION_COVERAGES`,
  SET_SIMULATION_COVERAGES = `@coverages/SET_SIMULATION_COVERAGES`,
  HANDLE_SIMULATION_COVERAGES = `@coverages/HANDLE_SIMULATION_COVERAGES`,
  GET_BASIC_COVERAGE_DETAILS = `@coverages/GET_BASIC_COVERAGE_DETAILS`,
  SET_BASIC_COVERAGE_DETAILS = `@coverages/SET_BASIC_COVERAGE_DETAILS`,
}

export enum LibertyIdEnum {
  BASICA = 'CBE10',
  DANOS_ELETRICOS = 'CMC01',
  ALUGUEL_INCENDIO = 'CMC02',
  QUEBRA_VIDROS = 'CMC03',
  DANOS_TERCEIROS = 'CMC04',
  ROUBO_BENS = 'CMC05',
  VENDAVAL = 'CMC06',
  ASSISTENCIA_24H_OFF = 'LAA01',
  ASSISTENCIA_24H_ON = 'LAA12',
}

export const MIN_BASIC_COVERAGE = 50_000
export const MIN_ADDITIONAL_COVERAGE = 5000

export interface ICoverage {
  id: number
  libertyId: string
  coverageName: string
  coverageDescription: string
  coverageDetail?: string
  coverageType: string
  insuredAmount?: number
  maximumInsuredAmount?: number
  Deductible?: {
    Value: number
  }
  maximumInsuredPercentage?: number
  selected?: boolean
  active: true
}

export interface CoveragesState {
  readonly basicCoverageDetail: string
  readonly coverages: ICoverage[]
  readonly simulationCoverages: ICoverage[]
}
