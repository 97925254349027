import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { ISummaryItem, SummaryList } from 'src/components/SummaryList/SummaryList'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { HireActions } from 'src/store/ducks/hire/actions'
import { H2, H3, PMedium, PSmall, SuccessSign } from 'src/styles/commons'
import { formatDataAndHours } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { LibertyIdEnum } from 'src/store/ducks/coverages/types'
import { CoveragesActions } from 'src/store/ducks/coverages/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { TAGS } from 'src/enums/TaggingEnum'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIContainer,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'
import { formatCpfOrCnpj, formatToBRL } from '@interco/lib-util'

export const Receipt = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const hiredQuote = useSelector(HireActions.getHiredQuote)
  const basicCoverageDetail = useSelector(CoveragesActions.getBasicCoverageDetail)
  const currentQuote = useSelector(QuoteActions.getCurrentQuote)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)
  const account_context = useSelector(NewRelicActions.getUserContext)

  const FORM_PAYMENT = 'Débito em conta'

  useBasePage({
    hideFaqBtn: true,
    showHomeBtn: true,
    navbarTitle: PageTitles.PROTECTED,
    backNavigationHandler: history.goBack,
    hideBackNavigationBtn: true,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA_SUCESSO.name, {
      ...TAGS.EMPRESA_PROTEGIDA_SUCESSO,
      ref_figma: '11',
      account_context,
      tela: 'Comprovante',
      content_action: 'tela carregada',
      forma_pag: FORM_PAYMENT,
      price: `${formatToBRL(Number(hiredQuote.monthlyValue))}/mês`,
      content_coverage: coveragesToString(),
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }, [])

  const iconSize = 24

  const iconArrow = (
    <Icons.ChevronRight width={iconSize} height={iconSize} color={theme.colors.primary.A500} />
  )
  const iconManual = (
    <Icons.BookOpen width={iconSize} height={iconSize} color={theme.colors.primary.A500} />
  )

  const successIcon = (
    <Icons.CheckCircle height={32} width={32} color={theme.colors.neutral.grayscale.A100} />
  )

  const companyItems = [
    { id: 1, text: 'CNPJ', value: formatCpfOrCnpj({ value: currentQuote.cnpj }) },
    { id: 2, text: 'Nome da empresa', value: hiredQuote.companyName },
    { id: 3, text: 'Protocolo', value: hiredQuote.protocol },
    { id: 4, text: 'Data da solicitação', value: formatDataAndHours(hiredQuote.registryDate) },
  ]

  const coverageItems: ISummaryItem[] = []

  if (hiredQuote.Coverages) {
    hiredQuote.Coverages.forEach((coverage) => {
      if (coverage.libertyId !== LibertyIdEnum.ASSISTENCIA_24H_OFF) {
        coverageItems.push({
          id: coverage.id,
          text:
            coverage.libertyId === LibertyIdEnum.BASICA
              ? `${basicCoverageDetail}`
              : coverage.coverageDescription,
          value: '',
        })
      }
    })
  }

  const paymentItems = [
    {
      id: 1,
      text: 'Valor total',
      value: `${formatToBRL(Number(hiredQuote.monthlyValue))}/mês`,
    },
    {
      id: 2,
      text: 'Forma de pagamento',
      value: FORM_PAYMENT,
    },
    {
      id: 3,
      text: 'Data da cobrança',
      value: `Todo dia ${hiredQuote.paymentDate}`,
    },
  ]

  const coveragesToString = () => {
    let coveragesStr = ''

    hiredQuote?.Coverages?.forEach((cov) => {
      if (cov.libertyId === LibertyIdEnum.ASSISTENCIA_24H_ON) {
        coveragesStr += ` ${cov.coverageDescription}: inclusa,`
      } else if (cov.insuredAmount) {
        coveragesStr += ` ${cov.coverageDescription}: ${formatToBRL(cov.insuredAmount)},`
      }
    })

    return coveragesStr
  }

  const renderHeroSuccess = (
    <>
      <SuccessSign>{successIcon}</SuccessSign>
      <H2 bold scale={600} margin="40px 0 8px" color={theme.colors.neutral.grayscale.A500}>
        Recebemos a sua solicitação!
      </H2>
      <PMedium scale={400} marginBottom="32px">
        Em breve, você terá o retorno pelo app e no seu e-mail. Quando aprovada, você poderá acessar
        todos os documentos do seu seguro por esses dois canais.
      </PMedium>
    </>
  )

  const handleClickManual = () => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA_MANUAL_PRODUTO.name, {
      ...TAGS.EMPRESA_PROTEGIDA_MANUAL_PRODUTO,
      ref_figma: '7',
      screen: 'Comprovante',
    })

    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: 'MANUAL',
      }),
    )
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button variant="primary" margin="0 0 16px" onClick={() => history.push(TypesRoutes.START)}>
        Ir para home
      </Button>
      {/* Verificar o uso do compartilhar comprovante */}
      {/* <Button
        variant="secondary"
        margin="0 0 24px"
        onClick={() => BaseBridge.shareContent('TODO share')}
      >
        Compartilhar comprovante
      </Button> */}
    </InterUIContainer>
  )

  return (
    <>
      {Object.keys(hiredQuote).length !== 0 && (
        <InterUIContainer margin="0" stickyFooter={stickyFooter}>
          <InterUIContainer margin="40px 24px 32px">{renderHeroSuccess}</InterUIContainer>

          <InterUISeparator height="8px" margin="0" />

          <InterUIContainer margin="0 24px 32px">
            <H3 margin="32px 0 16px">Empresa</H3>
            <SummaryList items={companyItems} />

            <InterUISeparator variant="small" height="1px" margin="24px 0" />

            <H3 margin="24px 0 16px">Coberturas</H3>
            <SummaryList items={coverageItems} />

            <InterUISeparator variant="small" height="1px" margin="24px 0" />

            <H3 margin="24px 0 16px">Pagamento</H3>
            <SummaryList items={paymentItems} />
          </InterUIContainer>

          <InterUISeparator height="8px" margin="32px 0 32px" />

          <InterUIContainer margin="0 24px">
            <InterUIListDescription
              data-testid="test-product-manual"
              iconLeft={iconManual}
              iconRight={iconArrow}
              alignAllItems="center"
              onClick={handleClickManual}
            >
              <PSmall marginBottom="0" bold>
                Manual do produto
              </PSmall>
            </InterUIListDescription>
          </InterUIContainer>
        </InterUIContainer>
      )}
    </>
  )
}
