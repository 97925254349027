import { call, put, select, takeLatest } from 'typed-redux-saga'
import CoveragesService from 'src/services/CoveragesService'
import { deepCloneArrayObjects, sortById } from 'src/utils/commons'

import { LoadingActions } from '../loading/actions'
import { errorHandlingSaga } from '../utils/providerSaga'
import { CoveragesTypes, LibertyIdEnum, MIN_ADDITIONAL_COVERAGE } from './types'
import { CoveragesActions } from './actions'
import { QuoteActions } from '../quote/actions'

function* getCoverages() {
  try {
    yield* put(LoadingActions.show())

    const coveragesResponse = yield* call(CoveragesService.getCoverages)

    const coveragesList = coveragesResponse.object

    const basicCoverage = coveragesList.filter((cov) => cov.libertyId === LibertyIdEnum.BASICA)[0]
    yield* put(
      CoveragesActions.setBasicCoverageDetail(basicCoverage.coverageDetail ?? 'Cobertura básica'),
    )

    yield* put(CoveragesActions.setCoverages(coveragesList))

    yield* handleSimulationCoverages()

    yield* put(LoadingActions.hide())
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
    }

    yield* errorHandlingSaga(error as Error, 'CoveragesActions.getCoverages', errorDetails)
  }
}

function* handleSimulationCoverages() {
  const coveragesList = yield* select(CoveragesActions.getCoverages)

  const coveragesListClone = deepCloneArrayObjects(coveragesList)
  const currentQuote = yield* select(QuoteActions.getCurrentQuote)

  if (Object.keys(currentQuote).length > 0) {
    const quoteCoverages = currentQuote.Coverages.sort(sortById())

    const updatedCoverages = coveragesListClone.map((coverageItem) => {
      const quoteCoverage = quoteCoverages.filter(
        (quoteCov) => quoteCov.libertyId === coverageItem.libertyId,
      )[0]

      coverageItem.insuredAmount = quoteCoverage?.insuredAmount ?? MIN_ADDITIONAL_COVERAGE
      coverageItem.selected = !!quoteCoverage
      coverageItem.Deductible = quoteCoverage?.Deductible

      return coverageItem
    })

    yield* put(CoveragesActions.setSimulationCoverages(updatedCoverages))
  } else {
    yield* put(CoveragesActions.setSimulationCoverages(coveragesList))
  }
}

export default function* watchCoverages() {
  yield* takeLatest(CoveragesTypes.GET_COVERAGES_REQUEST, getCoverages)
  yield* takeLatest(CoveragesTypes.HANDLE_SIMULATION_COVERAGES, handleSimulationCoverages)
}
