export const TAGS = {
  EMPRESA_PROTEGIDA: {
    name: 'EMPRESA_PROTEGIDA',
    ref_figma: '',
    account_context: '',
    content_id: '',
    tela: '',
    content_select: '',
    content_action: '',
  },
  EMPRESA_PROTEGIDA_FAQ_DUVIDA: {
    name: 'EMPRESA_PROTEGIDA_FAQ_DUVIDA',
    ref_figma: '',
    ref_type: 'botão',
    screen: '',
    content_name: '',
    product: '',
  },
  EMPRESA_PROTEGIDA_MANUAL_PRODUTO: {
    name: 'EMPRESA_PROTEGIDA_MANUAL_PRODUTO',
    ref_figma: '',
    ref_type: 'botão',
    screen: '',
    product: '',
  },
  EMPRESA_PROTEGIDA_SELECIONAR_FORMA_PAG: {
    name: 'EMPRESA_PROTEGIDA_SELECIONAR_FORMA_PAG',
    ref_figma: '',
    ref_type: 'botão',
    screen: '',
    content_name: '',
    product: '',
  },
  EMPRESA_PROTEGIDA_SUCESSO: {
    name: 'EMPRESA_PROTEGIDA_SUCESSO',
    ref_figma: '',
    account_context: '',
    content_action: '',
    forma_pag: '',
    price: '',
    content_coverage: '',
  },
}
