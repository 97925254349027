import { Reducer } from 'redux'

import { HireTypes, IHireState, IHiredQuote } from './types'

const INITIAL_STATE: IHireState = {
  hiredQuote: {} as IHiredQuote,
}

const reducer: Reducer<IHireState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HireTypes.SET_HIRED_QUOTE:
      return { ...state, hiredQuote: action.payload }
    default:
      return state
  }
}
export default reducer
