import styled from 'styled-components'

export const CommonQuestionsContent = styled.div`
  button {
    p {
      padding-right: 8px;
    }
  }

  .inter-ic-arrow-chevron-down {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 32px;
    min-height: 32px;

    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary.A100};
    transform-origin: center;

    ::before {
      font-size: 16px;
    }
  }
`
