import { Reducer } from 'redux'

import { DiscountsState, DiscountsTypes } from './types'

const INITIAL_STATE: DiscountsState = {
  discounts: [],
}

const reducer: Reducer<DiscountsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DiscountsTypes.SET_DISCOUNTS:
      return { ...state, discounts: action.payload }

    default:
      return state
  }
}
export default reducer
