import React, { useEffect, useLayoutEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'

import { ErrorController } from './components/Error/Error'
import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import Routes from './routes'
import { ErrorActions } from './store/ducks/error/actions'
import { ThemesEnum } from './enums/ThemesEnum'
import { NewRelicActions } from './store/ducks/newRelic/actions'

function App() {
  const dispatch = useDispatch()

  InterUIFonts()

  const [theme, setTheme] = useState<DefaultTheme>(InterTheme(ThemesEnum.LIGHT))

  useEffect(() => {
    const errorDetails = {
      title: 'Acesso bloqueado!',
      subTitle: 'Para ter acesso a esse conteúdo, por favor baixe o superApp do Inter.',
      disabledButton: true,
      disabledHome: true,
    }

    if (
      process.env.REACT_APP_NODE_ENV !== 'local' &&
      process.env.REACT_APP_NODE_ENV !== 'staging' &&
      BaseBridge.isBrowser()
    ) {
      dispatch(ErrorActions.show(errorDetails))
    }
  }, [])

  useLayoutEffect(() => {
    chooseTheme()
  }, [])

  const chooseTheme = () => {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      disabledButton: false,
    }

    BaseBridge.getAppInfo()
      .then((response) => {
        const isDarkMode = (response as unknown as { isDarkMode: boolean })?.isDarkMode

        isDarkMode ? setTheme(InterTheme(ThemesEnum.DARK)) : setTheme(InterTheme(ThemesEnum.LIGHT))

        const { application } = response

        dispatch(NewRelicActions.setUserContext(application))
      })
      .catch(() => dispatch(ErrorActions.show(errorDetails)))
  }

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <GlobalUIInterStyle />
        <BrowserRouter>
          <ErrorController />
          <Navbar />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    )
  )
}

export default App
