/**
 * Títulos disponíveis na aplicação.
 */
export enum PageTitles {
  PROTECTED = 'Seguro Empresa Protegida',
  MANUAL = 'Manual do produto',
  TERMS = 'Termos e condições',
  INSPECTION_CONTACT = 'Contato pra vistoria',
  ADDRESS = 'Informações da Empresa',
  COVERAGE_DETAILS = 'Detalhes da cobertura',
  FIELD_WORK = 'Ramo Atividade',
  COMMON_QUESTIONS = 'Perguntas frequentes',
  SUMMARY = 'Resumo',
  PAYMENT = 'Pagamento',
  POLICY = 'Apólice',
}
