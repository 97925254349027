import { H2, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { OMBUDSMAN_PHONE } from 'src/utils/constants'

import { InterUIListDescription } from '@interco/inter-ui-react-lib'

export interface IActionLinks {
  id: number
  icon: JSX.Element
  description: string
  action: () => void
}

export const Ombudsman = () => {
  const theme = useTheme()

  const iconSize = 16
  const colorPrimary = theme.colors.primary.A500
  const colorGrayscale = theme.colors.neutral.grayscale.A500

  const icons = {
    iconComputer: <Icons.ComputerCall width={iconSize} height={iconSize} color={colorGrayscale} />,
    iconEmail: <Icons.Email width={iconSize} height={iconSize} color={colorGrayscale} />,
    iconPhone: <Icons.Phone width={iconSize} height={iconSize} color={colorGrayscale} />,
  }

  const handleClickRedirect = () => {
    console.log('Redirect')
  }

  const handleClickEmail = () => {
    console.log('Email')
  }

  const handleClickCall = () => {
    BaseBridge.callPhoneNumber(OMBUDSMAN_PHONE)
  }

  const actionLinks: IActionLinks[] = [
    {
      id: 1,
      icon: icons.iconComputer,
      description: 'www.libertyseguros.com.br',
      action: handleClickRedirect,
    },
    {
      id: 2,
      icon: icons.iconEmail,
      description: 'ouvidoria@libertyseguros.com.br',
      action: handleClickEmail,
    },
    { id: 3, icon: icons.iconPhone, description: '0800 740 3994', action: handleClickCall },
  ]

  const renderActionLinks = actionLinks.map((item) => (
    <InterUIListDescription
      key={item.id}
      data-testid={`test-action-link-${item.id}`}
      iconLeft={item.icon}
      margin="0 0 16px"
      onClick={item.action}
      role="group"
    >
      <PMedium marginBottom="0px" bold color={colorPrimary}>
        {item.description}
      </PMedium>
    </InterUIListDescription>
  ))

  return (
    <>
      <H2 data-testid="ombudsmanTitle" marginBottom="8px">
        Ouvidoria
      </H2>

      {renderActionLinks}

      <PSmall scale={400} marginBottom="16px">
        Liberty Seguros S/A - CNPJ 61.550.141/0001-72 - Cód. SUSEP: 518-5 Processos SUSEP Nºs:
        15414.000170/2010-80.
      </PSmall>

      <PSmall scale={400} marginBottom="0">
        O registro do produto é automático e não representa aprovação ou recomendação por parte da
        Susep.
      </PSmall>
    </>
  )
}
