import { BtnLink, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CONTACT_PHONE } from 'src/utils/constants'

import { Content } from './Contact.style'

export function Contact() {
  const theme = useTheme()

  const iconPhone = <Icons.Phone width={16} height={16} color={theme.colors.base.black} />

  return (
    <Content data-testid="test__contact-number">
      <div>
        {iconPhone}
        <div>
          <BtnLink onClick={() => BaseBridge.callPhoneNumber(CONTACT_PHONE)}>0800 770 1318</BtnLink>
          <PSmall marginBottom="0" scale={400}>
            Capitais, regiões metropolitanas e demais localidades
          </PSmall>
        </div>
      </div>

      <PSmall marginBottom="0" scale={400}>
        Atendimento todos os dias, 24h.
      </PSmall>
    </Content>
  )
}
