export const mockCommonQuestions = [
  {
    id: 1,
    question: 'O que é o Seguro Empresa Protegida?',
    answer:
      'O Seguro Empresa Protegida é o seguro empresarial do Inter, indicado para pequenas, médias e grande empresas. O seguro conta com proteções personalizáveis para danos à estrutura e conteúdo da empresa que o contrata. Nele tem coberturas para incêndio, danos elétricos, roubo e muito mais.',
  },
  {
    id: 2,
    question: 'Existem coberturas obrigatórias? Se sim, quais?',
    answer:
      'Sim. A cobertura de incêndio, explosão, implosão, queda de raio, fumaça, desentulho e queda de aeronaves é obrigatória. Para contratar o seguro, é necessário contratar esta e qualquer outra cobertura adicional a escolha do cliente.',
  },
  {
    id: 3,
    question: 'Quais são as formas de pagamento?',
    answer:
      'O pagamento da mensalidade pode ser debitado da conta Inter. A cobrança do seguro acontece todo mês e, ao final do período de vigência, acontece a renovação automática, que pode ser desativada sem custos.',
  },
  {
    id: 4,
    question: 'Posso incluir mais de uma empresa no meu seguro?',
    answer:
      'Não, o Seguro Empresa Protegida pode ser contratado apenas para uma empresa. Se quiser contar com as coberturas para um segundo negócio, é necessário realizar uma nova contratação pelo app InterEmpresas.',
  },
  {
    id: 5,
    question: 'Posso cancelar o seguro antes do período de vigência terminar?',
    answer:
      'Sim. O seguro pode ser cancelado a qualquer momento, sem adição de taxas de cancelamento.',
  },
  {
    id: 6,
    question: 'Para quais quais tipos de empresas posso contratar o seguro?',
    answer:
      'O Seguro Empresa Protegida é ideal para empresas de prestação de serviços e comércio. Ainda não é possível contratar o seguro para empresas do ramo industrial ou empresas que tenham o armazenamento de produtos em depósito como o principal ramo de atividades.',
  },
  {
    id: 7,
    question: 'Se for preciso alterar minha apólice, o que devo fazer?',
    answer: 'O fluxo será conduzido pela área operacional da Inter Seguros.',
  },
  {
    id: 8,
    question: 'Tem alguma carência para utilização do seguro?',
    answer:
      'Não. Após solicitar a contratação pelo app, a empresa já conta com as coberturas selecionadas a partir da meia noite do dia em que solicitou. O pedido é analisado em até 15 dias úteis e, se aprovado, as coberturas continuam disponíveis para aproveitar.',
  },
  {
    id: 9,
    question: 'Como aciono o seguro?',
    answer:
      'Se precisar acionar o seguro, entre em contato com a nossa parceira. Ela pode te ajudar nesse canal pelo número 0800 770 1318. Nossos serviços estarão disponíveis, incluindo a assistência 24 horas.',
  },
]
