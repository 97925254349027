import BaseService from 'src/config/bridge/BaseService'
import { IDiscount } from 'src/store/ducks/discounts/types'

import { MockService } from './mockService'

export default class DiscountsService {
  static getDiscounts() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IDiscount[]>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/discounts`,
      headers,
    })
  }
}
