export enum ProfessionalActivitiesTypes {
  GET_PROFESSIONAL_ACTIVITIES = `@professionalActivities/GET_PROFESSIONAL_ACTIVITIES`,
  SET_PROFESSIONAL_ACTIVITIES = `@professionalActivities/SET_PROFESSIONAL_ACTIVITIES`,
  GET_PROFESSIONAL_ACTIVITIES_REQUEST = `@professionalActivities/GET_PROFESSIONAL_ACTIVITIES_REQUEST`,
}

export interface IProfessionalActivity {
  id: number
  cnaeCode: string
  activityDescription: string
  activityGroup: string
  active: boolean
}

export interface IProfessionalActivitiesResponse {
  offset: {
    page: number
    totalPages: number
  }
  professionalActivities: IProfessionalActivity[]
}

export interface ProfessionalActivitiesState {
  readonly professionalActivities: IProfessionalActivitiesResponse
}
