import styled from 'styled-components'

import { PSmall } from '../../../../../styles/commons'

export const ErrorMessage = styled(PSmall)`
  display: flex;
  justify-content: flex-end;
  right: 24px;
  margin: 4px 0 0;
  color: ${(props) => `${props.theme.colors.feedback.error.A500}`};
`
