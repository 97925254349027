import styled from 'styled-components'

export const HomeIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
  border-radius: 100%;
`
