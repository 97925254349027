import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { H2, PMedium, PSmall } from 'src/styles/commons'
import { maskZipCode } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIContainer,
  InterUIListDescription,
  InterUIProgressBar,
} from '@interco/inter-ui-react-lib'
import { formatCpfOrCnpj } from '@interco/lib-util'

export function SelectAddress() {
  const theme = useTheme()
  const history = useHistory()
  const account_context = useSelector(NewRelicActions.getUserContext)

  const currentQuotes = useSelector(QuoteActions.getCurrentQuote)
  const isLoading = useSelector(LoadingActions.get)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  useBasePage({
    navbarTitle: PageTitles.ADDRESS,
    backNavigationHandler: () => setTimeout(() => history.push(TypesRoutes.HOME), 1),
  })

  const iconSize = 24

  const iconChevronRight = (
    <Icons.ChevronRight height={iconSize} width={iconSize} color={theme.colors.base.white} />
  )

  const handleProfessionalActivities = () => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '5',
      account_context,
      content_id: 'Continuar',
      tela: 'Endereço',
      content_action: 'clique',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })

    history.push(TypesRoutes.PROFESSIONAL_ACTIVITIES)
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button
        onClick={handleProfessionalActivities}
        variant="primary"
        margin="24px 0 24px 0"
        alignContent="space-between"
        disabled={isLoading}
      >
        <PMedium marginBottom="0">Continuar</PMedium> {iconChevronRight}
      </Button>
    </InterUIContainer>
  )

  const renderAddress = (
    <>
      {currentQuotes.streetName}
      {currentQuotes.streetNumber && `, ${currentQuotes.streetNumber}`}
    </>
  )

  const renderAddressComplement = (
    <>
      {`${currentQuotes.districtName} `}
      {currentQuotes.streetExtraInformation && `- ${currentQuotes.streetExtraInformation} - `}
      {`CEP ${maskZipCode(currentQuotes.zipCode)}`}
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar progress="25%" />
      <InterUIContainer margin="24px 24px 32px">
        <PSmall marginBottom="24px" scale={300} bold>
          Etapa 1/4
        </PSmall>
        <H2 marginBottom="24px">Confira o CNPJ e endereço para o seguro</H2>

        <InterUIListDescription
          key="professional-test"
          id="professional-test"
          data-testid="address-test"
          withBorder
          borderColor={theme.colors.primary.A500}
          margin="16px 0 0"
        >
          <PMedium marginBottom="4px" bold>
            CNPJ: {formatCpfOrCnpj({ value: currentQuotes.cnpj })}
          </PMedium>
          <PSmall marginBottom="0" scale={400} bold>
            {renderAddress}
          </PSmall>
          <PSmall marginBottom="0" scale={400}>
            {renderAddressComplement}
          </PSmall>
          <PSmall marginBottom="0" scale={400}>
            {currentQuotes.city} | {currentQuotes.state}
          </PSmall>
        </InterUIListDescription>
      </InterUIContainer>
    </InterUIContainer>
  )
}
