import { combineReducers } from 'redux'

import address from './address'
import coverages from './coverages'
import discounts from './discounts'
import document from './document'
import error from './error'
import hire from './hire'
import inspectionContact from './inspectionContact'
import loading from './loading'
import navbar from './navbar'
import pathparams from './pathparams'
import payment from './payment'
import professionalActivities from './professionalActivities'
import quotesState from './quote'
import newRelic from './newRelic'

const rootReducer = combineReducers({
  address,
  navbar,
  loading,
  document,
  quotesState,
  error,
  pathparams,
  professionalActivities,
  discounts,
  coverages,
  inspectionContact,
  payment,
  hire,
  newRelic,
})

export default rootReducer
