import styled from 'styled-components'

export const Coverages = styled.ul`
  list-style: disc;
  padding-left: 22px;

  li {
    margin-bottom: 16px;
    line-height: 1.063rem;
    font-size: 14px;
  }
`
