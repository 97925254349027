import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Contact } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { AttentionSign, H1, H3, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { IListCoverages } from 'src/interfaces/UnderReview'

import {
  InterUIContainer,
  InterUIList,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export function UnderReview() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const iconAttention = (
    <Icons.Attention width={32} height={32} color={theme.colors.neutral.grayscale.A400} />
  )

  const iconArrow = <Icons.ChevronRight width={24} height={24} color={theme.colors.primary.A500} />
  const iconManual = <Icons.BookOpen width={24} height={24} color={theme.colors.primary.A500} />

  useBasePage({
    navbarTitle: PageTitles.PROTECTED,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  // TODO remove when calling the service
  const listCoverages: IListCoverages[] = [
    {
      id: 0,
      cover: 'Incêndio',
    },
    {
      id: 1,
      cover: 'Roubo de bens',
    },
    {
      id: 2,
      cover: 'Danos Elétricos',
    },
    {
      id: 3,
      cover: 'Assistência 24h',
    },
  ]

  const handleClickManual = () => {
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: 'MANUAL',
      }),
    )
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button variant="primary" margin="0 0 16px" onClick={() => BaseBridge.requestGoBack()}>
        Ir para home
      </Button>
      <Button variant="secondary" margin="0 0 24px" onClick={() => BaseBridge.shareContent('olá')}>
        Compartilhar comprovante
      </Button>
    </InterUIContainer>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="24px 24px 0">
        <AttentionSign>{iconAttention}</AttentionSign>
        <H1 margin="24px 0 8px">Sua contratação está sendo analisada</H1>

        <PMedium marginBottom="16px" scale={400}>
          Enquanto isso, a sua empresa já está protegida e conta com as coberturas solicitadas. Em
          até 15 dias você terá o retorno da solicitação pelo app e no seu e-mail.
        </PMedium>
        <PMedium marginBottom="16px" scale={400}>
          Se tiver dúvidas, entre em contato.
        </PMedium>

        <Contact />
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="0 24px">
        <InterUIContainer id="enterprise" margin="0">
          <H3 marginBottom="16px">Empresa</H3>

          <InterUIList
            variant="horizontal"
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                Chaladra Silva Santos
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Titular
              </PSmall>
            }
          />
          <InterUIList
            variant="horizontal"
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                Contabilidade JPS - ME
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Nome da empresa
              </PSmall>
            }
          />
          <InterUIList
            variant="horizontal"
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                202111120001
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Protocolo
              </PSmall>
            }
          />
          <InterUIList
            variant="horizontal"
            value={
              <PSmall bold marginBottom="0px">
                05/05/2021 às 15:45
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Data da solicitação
              </PSmall>
            }
          />
        </InterUIContainer>

        <InterUISeparator variant="small" margin="24px 0" />

        <InterUIContainer id="coverages" margin="0">
          <H3 marginBottom="16px">Coberturas</H3>

          {listCoverages.map((item) => (
            <PSmall key={item.id} marginBottom="16px" scale={400}>
              {item.cover}
            </PSmall>
          ))}
        </InterUIContainer>

        <InterUISeparator variant="small" margin="24px 0" />

        <InterUIContainer id="payment" margin="0">
          <H3 marginBottom="16px">Pagamento</H3>

          <InterUIList
            variant="horizontal"
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                R$ 84,50 / mês
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Valor total
              </PSmall>
            }
          />
          <InterUIList
            variant="horizontal"
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                Cartão de Crédito
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Forma de pagamento
              </PSmall>
            }
          />
          <InterUIList
            variant="horizontal"
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                Todo dia 15
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Data da cobrança
              </PSmall>
            }
          />
          <InterUIList
            variant="horizontal"
            value={
              <PSmall bold marginBottom="0px">
                Automática
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Renovação
              </PSmall>
            }
          />
        </InterUIContainer>
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="0 24px">
        <InterUIListDescription
          data-testid="test-product-manual"
          iconLeft={iconManual}
          iconRight={iconArrow}
          alignAllItems="center"
          onClick={handleClickManual}
        >
          <PSmall marginBottom="0" bold>
            Manual do produto
          </PSmall>
        </InterUIListDescription>
      </InterUIContainer>
    </InterUIContainer>
  )
}
