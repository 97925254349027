import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { AddressTypes, ISearchAddress, ISearchAddressRequest, IUpdateAddressRequest } from './types'

const updateAddressRequest = (data: IUpdateAddressRequest) =>
  action(AddressTypes.UPDATE_ADDRESS, data)

const getAddressByCepRequest = (data: ISearchAddressRequest) =>
  action(AddressTypes.GET_ADDRESS_BY_CEP, data)

const getSearchedAddress = (state: RootState) => state.address.searchedAddress

const setSearchedAddress = (data: ISearchAddress) => action(AddressTypes.SET_SEARCHED_ADDRESS, data)

export const AddressActions = {
  updateAddressRequest,
  getAddressByCepRequest,
  setSearchedAddress,
  getSearchedAddress,
}
