import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MockService } from 'src/services/mockService'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'

export function PathQueryParams() {
  const dispatch = useDispatch()
  const location = useLocation()

  const whitelist = ['/']

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      const searchParams = new URLSearchParams(location.search)

      setPathParams(location.pathname, searchParams)
    }
  }

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    if (params.getAll.length > 0 && pathname === '/') {
      const utmCampaignParams = {
        utm_campaign: params.get('utm_campaign') || undefined,
      }

      const queryShouldMock = {
        shouldMock: params.get('shouldMock') || undefined,
      }

      const queryMockResponse = {
        mockResponse: params.get('mockResponse') || undefined,
      }

      const queryAccount = {
        account: params.get('account') || undefined,
      }
      const queryApiKey = {
        apiKey: params.get('apiKey') || undefined,
      }

      if (queryShouldMock.shouldMock) {
        const shouldMock = queryShouldMock.shouldMock === 'true'
        MockService.prepareMock({
          shouldMock,
          account: queryAccount.account,
          apiKey: queryApiKey.apiKey,
        })
      }

      if (queryMockResponse.mockResponse) {
        const shouldMock = true
        const { mockResponse } = queryMockResponse
        MockService.prepareMock({
          shouldMock,
          mockResponse,
          account: queryAccount.account,
          apiKey: queryApiKey.apiKey,
        })
      }

      if (utmCampaignParams.utm_campaign) {
        dispatch(PathParamsActions.setUtmCampaignPathParams(utmCampaignParams.utm_campaign))
      }
    }
  }

  return handler
}
