import { Fragment } from 'react'

import { AfterSalesEnum } from 'src/enums/AfterSalesEnum'
import { ICoverage, LibertyIdEnum } from 'src/store/ducks/coverages/types'
import { PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIAccordion, InterUIList, InterUISeparator } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { Contact } from '../Contact/Contact'

export interface IMenuCheckout {
  id: number
  icon: JSX.Element
  title: string
  content: JSX.Element | JSX.Element[] | undefined
}

interface MenuCheckoutProps {
  status: string
  coverages: ICoverage[]
}

export const MenuCheckout = ({ status, coverages }: MenuCheckoutProps) => {
  const theme = useTheme()

  const iconSize = 24
  const colorGrayscale = theme.colors.neutral.grayscale.A500

  const icons = {
    iconServices: <Icons.Services width={iconSize} height={iconSize} color={colorGrayscale} />,
    iconApps: <Icons.Apps width={iconSize} height={iconSize} color={colorGrayscale} />,
    iconPhoneCall: <Icons.PhoneCall width={iconSize} height={iconSize} color={colorGrayscale} />,
    iconCanceled: <Icons.Canceled width={iconSize} height={iconSize} color={colorGrayscale} />,
  }

  const skipLAA01 = () =>
    coverages.filter((coverage) => coverage.libertyId !== LibertyIdEnum.ASSISTENCIA_24H_OFF)

  const filteredCoverages = (coverages && skipLAA01()) ?? []

  const renderListCoverages = filteredCoverages?.map((item, index) => {
    const margin = index !== filteredCoverages.length - 1 ? '' : '0 0 16px'
    return (
      <Fragment key={item.id}>
        <InterUIList
          margin={margin}
          data-testid="test__coverage-item"
          text={
            <PSmall scale={400} margin="0 16px 0 0">
              {item.coverageDescription}
            </PSmall>
          }
          value={
            <PSmall scale={400} bold marginBottom="0">
              {item.libertyId === LibertyIdEnum.ASSISTENCIA_24H_ON
                ? 'Inclusa'
                : formatToBRL(Number(item.insuredAmount))}
            </PSmall>
          }
        />
        {index !== filteredCoverages.length - 1 && (
          <InterUISeparator variant="small" margin="16px 0" />
        )}
      </Fragment>
    )
  })

  const renderContacts = <Contact />

  const renderCancelSubscription = (
    <>
      <PSmall scale={400} marginBottom="16px">
        Para fazer o cancelamento do seu Seguro Empresa Protegida, entre em contato com a nossa
        parceira Liberty Seguros e solicite o cancelamento.
      </PSmall>
      <Contact />
    </>
  )

  const menuCheckout: IMenuCheckout[] = [
    {
      id: 0,
      icon: icons.iconServices,
      title: 'Coberturas',
      content: renderListCoverages,
    },
    {
      id: 1,
      icon: icons.iconApps,
      title: 'Contatos',
      content: renderContacts,
    },
  ]

  if (status === AfterSalesEnum.IN_ANALYSIS || status === AfterSalesEnum.ACTIVE) {
    const cancelItem = {
      id: 2,
      icon: icons.iconCanceled,
      title: 'Cancelamento',
      content: renderCancelSubscription,
    }

    menuCheckout.push(cancelItem)
  }

  const renderMenuCheckout = menuCheckout.map((item) => (
    <InterUIAccordion
      id={item.title}
      key={item.id}
      data-testid={`test-menu-${item.id}`}
      border={item.id < menuCheckout.length - 1 ? 'bottom' : 'none'}
      header={
        <>
          {item.icon}
          <PSmall margin="0 0 0 16px">{item.title}</PSmall>
        </>
      }
    >
      {item.content}
    </InterUIAccordion>
  ))

  return <>{renderMenuCheckout}</>
}
