import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { SagaWithHistory } from '../utils/providerSaga'
import {
  ICalculateRequest,
  ICreateSimulationRequest,
  IQuote,
  IQuotesRequest,
  QuoteTypes,
} from './types'

const createQuote = (data?: SagaWithHistory) => action(QuoteTypes.CREATE_QUOTE, data)
const createSimulation = (data: ICreateSimulationRequest) =>
  action(QuoteTypes.CREATE_SIMULATION, data)
const calculateSimulation = (data: ICalculateRequest) =>
  action(QuoteTypes.CALCULATE_SIMULATION, data)
const getQuotesRequest = (data?: IQuotesRequest) => action(QuoteTypes.GET_QUOTES_REQUEST, data)
const getQuotes = (state: RootState) => state.quotesState.quotes
const setQuotes = (data: IQuote[]) => action(QuoteTypes.SET_QUOTES, data)
const getCurrentQuote = (state: RootState) => state.quotesState.currentQuote
const setCurrentQuote = (data: IQuote) => action(QuoteTypes.SET_CURRENT_QUOTE, data)
const getWasModified = (state: RootState) => state.quotesState.wasModified
const setWasModified = (data: boolean) => action(QuoteTypes.SET_WAS_MODIFIED, data)

export const QuoteActions = {
  createQuote,
  createSimulation,
  calculateSimulation,
  getQuotes,
  getQuotesRequest,
  setQuotes,
  getCurrentQuote,
  setCurrentQuote,
  getWasModified,
  setWasModified,
}
