import { call, put, select, takeLatest } from 'typed-redux-saga'
import DiscountsService from 'src/services/DiscountsService'

import { LoadingActions } from '../loading/actions'
import { errorHandlingSaga } from '../utils/providerSaga'
import { DiscountsTypes } from './types'
import { DiscountsActions } from './actions'
import { QuoteActions } from '../quote/actions'

function* getDiscounts() {
  try {
    yield* put(LoadingActions.show())

    const discountsResponse = yield* call(DiscountsService.getDiscounts)

    const discountsList = discountsResponse.object
    const currentQuote = yield* select(QuoteActions.getCurrentQuote)

    if (Object.keys(currentQuote).length > 0) {
      const quoteDiscounts = currentQuote.Discounts

      const updatedDiscounts = discountsList.map((discountItem) => {
        const quoteDiscount = quoteDiscounts.filter(
          (quoteDisc) => quoteDisc.id === discountItem.id,
        )[0]

        discountItem.selected = !!quoteDiscount

        return discountItem
      })

      yield* put(DiscountsActions.setDiscounts(updatedDiscounts))
    } else {
      yield* put(DiscountsActions.setDiscounts(discountsList))
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
    }

    yield* errorHandlingSaga(error as Error, 'DiscountsActions.getDiscounts', errorDetails)
  }
}

function* deselectAllDiscounts() {
  const discountsResponse = yield* call(DiscountsService.getDiscounts)
  const discountsList = discountsResponse.object

  const updatedDiscounts = discountsList.map((discountItem) => {
    discountItem.selected = false
    return discountItem
  })

  yield* put(DiscountsActions.setDiscounts(updatedDiscounts))
}

export default function* watchDiscounts() {
  yield* takeLatest(DiscountsTypes.GET_DISCOUNTS_REQUEST, getDiscounts)
  yield* takeLatest(DiscountsTypes.DESELECT_ALL_DISCOUNTS, deselectAllDiscounts)
}
