import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AfterSalesEnum } from 'src/enums/AfterSalesEnum'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { PXSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { CONTACT_PHONE } from 'src/utils/constants'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { ITagAfterSales } from 'src/pages/afterSales/AfterSalesStatus/AfterSalesStatus'
import { TAGS } from 'src/enums/TaggingEnum'

import { ActionsIcons, ContentIcons, GroupIcons } from './QuickAccessBar.style'

interface QuickAccessBarProps {
  status: string
  newRelicInfo: {
    ref_figma: string
    account_context: string
    status: string
  }
}

interface IQuickItem {
  id: number
  icon: JSX.Element
  description: string
  disabled: boolean
  action: () => void
}

export const QuickAccessBar = ({ status, newRelicInfo }: QuickAccessBarProps) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const iconSize = 24
  const colorPrimary = theme.colors.primary.A500

  const tagAfterSales = ({ content_id, content_action }: ITagAfterSales) => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: newRelicInfo.ref_figma,
      account_context: newRelicInfo.account_context,
      status: newRelicInfo.status,
      content_id: content_id ?? 'Pós venda',
      tela: 'Pós venda',
      content_action: content_action ?? 'tela carregada',
    })
  }

  const handlePhoneCall = () => {
    tagAfterSales({ content_id: 'Acionar seguro', content_action: 'clique' })
    BaseBridge.callPhoneNumber(CONTACT_PHONE)
  }

  const handleClickManual = () => {
    tagAfterSales({ content_id: 'Manual', content_action: 'clique' })
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: 'MANUAL',
      }),
    )
  }

  const handleClickPolicy = () => {
    tagAfterSales({ content_id: 'Apólice', content_action: 'clique' })

    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: 'POLICY',
      }),
    )
  }

  const quickItems: { [status: string]: IQuickItem } = {
    SEGURO: {
      id: 1,
      icon: <Icons.PhoneCall width={iconSize} height={iconSize} color={colorPrimary} />,
      description: 'Acionar seguro',
      disabled: false,
      action: handlePhoneCall,
    },
    MANUAL: {
      id: 2,
      icon: <Icons.PdfFile width={iconSize} height={iconSize} color={colorPrimary} />,
      description: 'Manual do produto',
      disabled: false,
      action: handleClickManual,
    },
    POLICY: {
      id: 3,
      icon: <Icons.Receipt width={iconSize} height={iconSize} color={colorPrimary} />,
      description: 'Apólice',
      disabled: false,
      action: handleClickPolicy,
    },
  }

  const allItems = [quickItems.SEGURO, quickItems.MANUAL, quickItems.POLICY]

  const quickItemsSelection: { [status: string]: IQuickItem[] } = {
    [AfterSalesEnum.CREATED]: allItems,
    [AfterSalesEnum.IN_ANALYSIS]: [quickItems.MANUAL],
    [AfterSalesEnum.ACTIVE]: allItems,
    [AfterSalesEnum.NOT_APPROVED]: [quickItems.MANUAL],
    [AfterSalesEnum.CANCELED]: [quickItems.POLICY],
    [AfterSalesEnum.FINISHED]: [quickItems.POLICY],
  }

  const filteredAccessBar = quickItemsSelection[status]

  const renderQuickAccessBar = filteredAccessBar.map((item) => (
    <ActionsIcons
      key={item.id}
      data-testid={`test__links-${item.id}`}
      className={item.disabled ? 'disabled' : 'undefined'}
      onClick={() => {
        item.action()
      }}
    >
      <ContentIcons>{item.icon}</ContentIcons>
      <PXSmall bold margin="8px 0 0" textAlign="center">
        {item.description}
      </PXSmall>
    </ActionsIcons>
  ))

  return <GroupIcons>{renderQuickAccessBar}</GroupIcons>
}
