import BaseService from 'src/config/bridge/BaseService'
import { IHireQuoteRequest, IHiredQuote } from 'src/store/ducks/hire/types'

export default class HireService {
  static createHire(account: string, hireQuoteRequest: IHireQuoteRequest) {
    const headers = {
      bankAccount: account,
    }

    const data = hireQuoteRequest as unknown as Record<string, string>

    return BaseService.doExecute<IHiredQuote>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/contracts`,
      headers,
      data,
    })
  }

  static async getHire(account: string, proposalId: string) {
    const headers = {
      bankAccount: account,
    }

    return BaseService.doExecute<IHiredQuote>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/contracts/${proposalId}`,
      headers,
    })
  }
}
