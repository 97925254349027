import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { HireActions } from 'src/store/ducks/hire/actions'
import { InspectionContactActions } from 'src/store/ducks/inspectionContact/actions'
import { PaymentCardActions } from 'src/store/ducks/payment/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { BtnLink, H2, H3, PMedium, PSmall } from 'src/styles/commons'
import { formatFirstLetterCapital, maskZipCode } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { ICoverage, LibertyIdEnum } from 'src/store/ducks/coverages/types'
import { CoveragesActions } from 'src/store/ducks/coverages/actions'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIAlert,
  InterUIContainer,
  InterUIList,
  InterUIListDescription,
  InterUIProgressBar,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'
import { cellphoneMask, formatCpfOrCnpj, formatToBRL, uuid } from '@interco/lib-util'

import { AlertContainer, AlertList, FullValueHireContainer } from './Summary.styles'

export interface IFullValueBlock {
  title: string
  price: string
}

export const Summary = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const account_context = useSelector(NewRelicActions.getUserContext)

  const basicCoverageDetail = useSelector(CoveragesActions.getBasicCoverageDetail)
  const currentQuotes = useSelector(QuoteActions.getCurrentQuote)
  const selectedContact = useSelector(InspectionContactActions.getSelectContact)
  const paymentCard = useSelector(PaymentCardActions.getPaymentCard)

  useBasePage({
    hideFaqBtn: false,
    showHomeBtn: false,
    navbarTitle: PageTitles.SUMMARY,
    backNavigationHandler: () => setTimeout(() => history.push(TypesRoutes.QUOTATION), 1),
  })

  const iconSize = 24

  const iconArrow = (
    <Icons.ChevronRight width={iconSize} height={iconSize} color={theme.colors.primary.A500} />
  )

  const iconArrowSmall = (
    <Icons.ChevronRight width={16} height={16} color={theme.colors.primary.A500} />
  )

  const iconManual = (
    <Icons.BookOpen width={iconSize} height={iconSize} color={theme.colors.primary.A500} />
  )

  const successIcon = (
    <Icons.CheckFill height={16} width={16} color={theme.colors.feedback.success.A500} />
  )

  const iconEdit = (
    <Icons.Edit
      height={iconSize}
      width={iconSize}
      color={theme.colors.primary.A500}
      onClick={() => history.push(TypesRoutes.CHANGE_INSPECTION_CONTACT)}
      data-testid="test__changeInspectionContact"
    />
  )

  const iconPayment = (
    <Icons.Dollar width={iconSize} height={iconSize} color={theme.colors.neutral.grayscale.A500} />
  )

  const setPaymentMethods =
    Object.keys(paymentCard).length === 0
      ? {
          border: theme.colors.neutral.grayscale.A200,
          icon: iconPayment,
          title: 'Formas de pagamento',
          arrow: iconArrow,
        }
      : paymentCard

  const [paymentCardConfig] = useState(setPaymentMethods)

  const skipLAA01 = (coverages: ICoverage[]) =>
    coverages.filter((coverage) => coverage.libertyId !== LibertyIdEnum.ASSISTENCIA_24H_OFF)

  const filteredCoverages = (currentQuotes?.Coverages && skipLAA01(currentQuotes.Coverages)) ?? []

  const handleClickManual = () => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA_MANUAL_PRODUTO.name, {
      ...TAGS.EMPRESA_PROTEGIDA_MANUAL_PRODUTO,
      ref_figma: '7',
      screen: 'Resumo',
    })
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: 'MANUAL',
      }),
    )
  }

  const coveragesToString = () => {
    let coveragesStr = ''

    filteredCoverages.forEach((cov) => {
      if (cov.libertyId === LibertyIdEnum.ASSISTENCIA_24H_ON) {
        coveragesStr += ` ${cov.coverageDescription}: inclusa,`
      } else if (cov.insuredAmount) {
        coveragesStr += ` ${cov.coverageDescription}: ${formatToBRL(cov.insuredAmount)},`
      }
    })

    return coveragesStr
  }

  const handleHire = () => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '10',
      account_context,
      content_action: 'clique',
      forma_pag: paymentCardConfig.title,
      price: `${formatToBRL(currentQuotes.paymentMonthly ?? 0)}/mês`,
      content_coverage: coveragesToString(),
    })

    dispatch(
      HireActions.createHire({
        history,
        pathname: TypesRoutes.RECEIPT,
        inspector: {
          name: selectedContact.name,
          phoneNumber: selectedContact.phone,
        },
      }),
    )
  }

  const renderCoverages = (
    <>
      {Object.keys(currentQuotes).length !== 0 &&
        filteredCoverages.map((coverage) => {
          const key = `advantage-${coverage.libertyId}`

          return (
            <InterUIListDescription
              key={key}
              data-testid={`test-${key}`}
              iconRight={successIcon}
              alignAllItems="initial"
              margin="32px 0"
              borderBottom={filteredCoverages[filteredCoverages.length - 1].id !== coverage.id}
              small
            >
              <InterUIList
                variant="horizontal"
                data-testid="test__coverage-list"
                margin="2px 0 0" // Margin específica de 2px para alinhamento com ícone
                text={
                  <PMedium scale={400} marginBottom="0px">
                    {coverage.libertyId === LibertyIdEnum.BASICA
                      ? `${basicCoverageDetail}`
                      : coverage.coverageDescription}
                  </PMedium>
                }
                value={
                  <PMedium bold marginBottom="0px">
                    {coverage.libertyId === LibertyIdEnum.ASSISTENCIA_24H_ON
                      ? 'Inclusa'
                      : formatToBRL(coverage.insuredAmount ?? 0)}
                  </PMedium>
                }
              />
            </InterUIListDescription>
          )
        })}
    </>
  )

  const FullValueBlock = ({ title, price }: IFullValueBlock) => (
    <div>
      <PSmall marginBottom="4px">{title}</PSmall>
      <H3 marginBottom="0px">{price}</H3>
    </div>
  )

  const iconArrowHireButton = (
    <Icons.ChevronRight
      width={iconSize}
      height={iconSize}
      color={
        paymentCardConfig.title === 'Formas de pagamento' ||
        (currentQuotes.needInspectionRisk && Object.keys(selectedContact).length === 0)
          ? theme.colors.neutral.grayscale.A300
          : theme.colors.base.white
      }
    />
  )

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <FullValueHireContainer>
        <FullValueBlock
          title="Valor total"
          price={`${formatToBRL(currentQuotes.paymentMonthly ?? 0)}/mês`}
        />
        <Button
          style={{ minWidth: 'auto' }}
          alignContent="space-between"
          variant="primary"
          onClick={() => handleHire()}
          data-testid="test__hire"
          disabled={
            paymentCardConfig.title === 'Formas de pagamento' ||
            (currentQuotes.needInspectionRisk && Object.keys(selectedContact).length === 0)
          }
        >
          <PMedium marginBottom="0">Contratar</PMedium>
          {iconArrowHireButton}
        </Button>
      </FullValueHireContainer>
    </InterUIContainer>
  )

  return (
    <>
      {Object.keys(currentQuotes).length !== 0 && (
        <InterUIContainer margin="0" stickyFooter={stickyFooter}>
          <InterUIProgressBar progress="100%" />

          <InterUIContainer margin="24px 24px 32px">
            <PSmall marginBottom="24px" scale={300} bold>
              Etapa 4/4
            </PSmall>
            <H2 margin="36px 0 24px 0">Revise os detalhes do seguro</H2>
          </InterUIContainer>

          <InterUIContainer margin="0 24px 32px">
            <H3 margin="32px 0 8px">Dados da empresa</H3>
            <PMedium bold>{currentQuotes.companyName}</PMedium>
            <PSmall scale={400} margin="0">
              CNPJ: {formatCpfOrCnpj({ value: currentQuotes.cnpj })}
            </PSmall>
            <PSmall scale={400} marginBottom="16px">
              {formatFirstLetterCapital(currentQuotes.libertyStreetType)} {currentQuotes.streetName}
              , {currentQuotes.streetNumber}, {currentQuotes.districtName} CEP{' '}
              {maskZipCode(currentQuotes.zipCode)} - {currentQuotes.city} | {currentQuotes.state}
            </PSmall>

            <PSmall bold>Ramo de atividade:</PSmall>
            <PSmall scale={400} marginBottom="8px">
              {currentQuotes.ProfessionalActivity.activityDescription}
            </PSmall>

            {currentQuotes.Discounts.length > 0 && (
              <>
                <PSmall bold>Características:</PSmall>
                {currentQuotes.Discounts.map((discount) => (
                  <AlertList key={uuid()}>
                    <li style={{ marginLeft: '18px' }}>
                      <PSmall scale={400}>{discount.description}</PSmall>
                    </li>
                  </AlertList>
                ))}
              </>
            )}
          </InterUIContainer>

          <InterUISeparator height="8px" margin="0 0 32px" />

          {currentQuotes.Coverages.length > 0 && (
            <>
              <InterUIContainer margin="0 24px 32px">
                {currentQuotes.Coverages.length > 0 && (
                  <>
                    <H3 margin="32px 0 40px">Coberturas selecionadas:</H3>
                    {renderCoverages}
                  </>
                )}
              </InterUIContainer>

              <InterUIContainer margin="0 24px 32px">
                <InterUIAlert>
                  <PSmall bold>As coberturas não atendem:</PSmall>
                  <AlertList>
                    <li>Imóveis feitos de madeira e isopainel</li>
                    <li>Imóveis tombados pelo Patrimônio Histórico </li>
                    <li>
                      Imóveis que tenham o armazenamento de produtos em depósito como ramo principal
                    </li>
                  </AlertList>
                </InterUIAlert>
              </InterUIContainer>

              <InterUISeparator height="8px" margin="0 0 32px" />
            </>
          )}

          {currentQuotes.needInspectionRisk && (
            <>
              <InterUIContainer margin="0 24px 32px">
                <H3 margin="32px 0 8px">Vistoria do imóvel</H3>
                <PMedium scale={400}>
                  Defina um contato para agendamento e realização da vistoria.
                </PMedium>
                {Object.keys(selectedContact).length === 0 ? (
                  <InterUIListDescription
                    withBorder
                    borderColor={theme.colors.primary.A500}
                    iconRight={iconArrowSmall}
                    onClick={() => history.push(TypesRoutes.CHANGE_INSPECTION_CONTACT)}
                    margin="32px 0"
                    small
                    data-testid="test__addInspectionContact"
                  >
                    <PSmall marginBottom="0px" color={theme.colors.primary.A500} bold>
                      Definir contato
                    </PSmall>
                  </InterUIListDescription>
                ) : (
                  <InterUIListDescription iconRight={iconEdit} margin="32px 0">
                    <InterUIList
                      variant="vertical"
                      text={<PSmall marginBottom="0px">{selectedContact.name}</PSmall>}
                      value={
                        <PSmall marginBottom="0px" bold>
                          {cellphoneMask(selectedContact.phone)}
                        </PSmall>
                      }
                    />
                  </InterUIListDescription>
                )}
              </InterUIContainer>

              <InterUISeparator height="8px" margin="0 0 32px" />
            </>
          )}

          <InterUIContainer margin="0 24px 32px">
            <H2 margin="36px 0 24px 0">Como você quer pagar?</H2>
            <InterUIListDescription
              withBorder
              borderColor={paymentCardConfig.border}
              iconLeft={paymentCardConfig.icon}
              iconRight={paymentCardConfig.arrow}
              alignAllItems="center"
              onClick={() => history.push(TypesRoutes.PAYMENT)}
              data-testid="test__payment"
            >
              <PMedium marginBottom="0" bold>
                {paymentCardConfig.title}
              </PMedium>
            </InterUIListDescription>
          </InterUIContainer>

          <InterUISeparator height="8px" margin="0 0 32px" />

          <InterUIContainer margin="0 24px 32px">
            <H3 margin="32px 0 8px">Todos os detalhes do seu seguro estarão disponíveis no app</H3>
            <PMedium scale={400}>
              Após a contratação, você poderá usar o app para consultar o manual, a apólice e os
              canais de acionamento do seguro.
            </PMedium>
          </InterUIContainer>

          <InterUISeparator height="8px" margin="0 0 32px" />

          <InterUIContainer margin="0 24px">
            <InterUIListDescription
              withBorder
              data-testid="test-product-manual"
              iconLeft={iconManual}
              iconRight={iconArrow}
              alignAllItems="center"
              onClick={handleClickManual}
            >
              <PSmall marginBottom="0" bold>
                Manual do produto
              </PSmall>
            </InterUIListDescription>
          </InterUIContainer>

          <InterUIContainer margin="0">
            <AlertContainer>
              <PSmall>
                Ao contratar, declaro que li e concordo com os <BtnLink>Termos e Condições</BtnLink>{' '}
                do Seguro Empresa Protegida.
              </PSmall>
            </AlertContainer>
          </InterUIContainer>
        </InterUIContainer>
      )}
    </>
  )
}
