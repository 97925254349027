import { Reducer } from 'redux'

import { IQuote, IQuotesState, QuoteTypes } from './types'

const INITIAL_STATE: IQuotesState = {
  quotes: {} as IQuote[],
  currentQuote: {} as IQuote,
  wasModified: false,
}

const reducer: Reducer<IQuotesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuoteTypes.SET_QUOTES:
      return { ...state, quotes: action.payload }
    case QuoteTypes.SET_CURRENT_QUOTE:
      return { ...state, currentQuote: action.payload }
    case QuoteTypes.SET_WAS_MODIFIED:
      return { ...state, wasModified: action.payload }
    default:
      return state
  }
}
export default reducer
