import React, { useEffect } from 'react'

import { Button } from 'src/components'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { PMedium } from 'src/styles/commons'
import { useForm, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import { IInspectionContact } from 'src/store/ducks/inspectionContact/types'
import { useDispatch, useSelector } from 'react-redux'
import { InspectionContactActions } from 'src/store/ducks/inspectionContact/actions'

import { InterUIContainer, InterUIInputGroup } from '@interco/inter-ui-react-lib'
import { cellphoneMask } from '@interco/lib-util'

import { ErrorMessage } from './ChangeInspectionContact.styles'

export function ChangeInspectionContact() {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IInspectionContact>({ mode: 'onChange' })

  const onSubmit = (data: IInspectionContact) => {
    dispatch(InspectionContactActions.setSelectContact(data))
    history.goBack()
  }

  const name = watch('name')
  const phone = watch('phone')

  const selectedContact = useSelector(InspectionContactActions.getSelectContact)

  useEffect(() => {
    if (Object.keys(selectedContact).length !== 0) {
      reset({ name: selectedContact.name, phone: cellphoneMask(selectedContact.phone) })
    } else {
      reset({})
    }
  }, [])

  useBasePage({
    navbarTitle: PageTitles.INSPECTION_CONTACT,
    backNavigationHandler: history.goBack,
  })

  const lettersOnly = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const onlyNumbers = /\d+/

    if (onlyNumbers.test(e.key)) {
      e.preventDefault()
    }
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button
        type="submit"
        variant="primary"
        margin="0 0 16px"
        disabled={
          name === undefined ||
          phone === undefined ||
          errors.name?.message === '' ||
          errors.phone?.message === ''
        }
        data-testid="submit"
      >
        Salvar
      </Button>
    </InterUIContainer>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIContainer margin="24px">
          <PMedium marginBottom="24px">
            Informe os dados da pessoa que será responsável por receber e agendar a vistoria.
          </PMedium>
          <InterUIInputGroup invalid={!!errors.name}>
            <label htmlFor="name">Nome completo</label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              onKeyPress={(event) => lettersOnly(event)}
            />
          </InterUIInputGroup>
          {errors.name && <ErrorMessage>Digite nome e sobrenome</ErrorMessage>}

          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputMask
                mask="(99) 99999-9999"
                maskChar=""
                value={field.value}
                onChange={field.onChange}
                autoComplete="off"
              >
                {() => (
                  <InterUIInputGroup margin="16px 0 0" invalid={!!errors.phone}>
                    <label htmlFor="phone">Telefone</label>
                    <input
                      {...register('phone', {
                        required: true,
                        pattern: /\([1-9]\d\)\s9?\d{4}-\d{4}/g,
                      })}
                      type="text"
                      id="phone"
                      maxLength={15}
                      placeholder="(__) _____-____"
                    />
                  </InterUIInputGroup>
                )}
              </InputMask>
            )}
          />
          {errors.phone?.type === 'required' && (
            <ErrorMessage>Digite o número para contato</ErrorMessage>
          )}
          {errors.phone?.type === 'pattern' && (
            <ErrorMessage>Digite um número de celular válido</ErrorMessage>
          )}
        </InterUIContainer>
      </InterUIContainer>
    </form>
  )
}
