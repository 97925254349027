import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { SagaWithHistory } from '../utils/providerSaga'
import { HireTypes, IHireQuoteRequest, IHiredQuote } from './types'

const createHire = (data: IHireQuoteRequest) => action(HireTypes.CREATE_HIRE, data)
const getHireRequest = (data: SagaWithHistory) => action(HireTypes.GET_HIRED_QUOTE_REQUEST, data)
const getHiredQuote = (state: RootState) => state.hire.hiredQuote
const setHiredQuote = (data: IHiredQuote) => action(HireTypes.SET_HIRED_QUOTE, data)

export const HireActions = {
  createHire,
  getHireRequest,
  getHiredQuote,
  setHiredQuote,
}
