import { AnyAction } from 'redux'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks//utils/providerSaga'
import { takeLatest } from 'typed-redux-saga'

import { DocumentTypes } from './types'

function* redirectToDocument({ payload }: AnyAction) {
  try {
    redirectThroughSaga(payload)
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
    }
    yield* errorHandlingSaga(error as Error, '', errorDetails)
  }
}

export default function* watchDocuments() {
  yield* takeLatest(DocumentTypes.SET_REDIRECT_TO_DOCUMENT, redirectToDocument)
}
