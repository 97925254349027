import styled from 'styled-components'

export const AlertList = styled.ul`
  list-style: disc;
  line-height: 0.9375rem;
  font-size: 12px;
`

export const AlertContainer = styled.div<{ backgroundColor?: string }>`
  margin-top: 28px;
  padding: 32px 24px 12px 24px;
  display: flex;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
`

export const FullValueHireContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 16px;
`
