import React from 'react'

import { PMedium } from 'src/styles/commons'
import { useBasePage } from 'src/hooks/useBasePage'
import { PageTitles } from 'src/enums/pageTitles'
import { mockCommonQuestions } from 'src/mocks/CommonQuestions'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { useSelector } from 'react-redux'

import {
  InterUIAccordion,
  InterUIBabi,
  InterUIContainer,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { CommonQuestionsContent } from './CommonQuestions.styles'

interface IFaqItem {
  id: number
  question: string
  answer: string
}

export const CommonQuestions = () => {
  const history = useHistory()
  const account_context = useSelector(NewRelicActions.getUserContext)

  useBasePage({
    navbarTitle: PageTitles.COMMON_QUESTIONS,
    backNavigationHandler: history.goBack,
    hideFaqBtn: true,
  })

  const handleClick = (faq: IFaqItem) => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA_FAQ_DUVIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA_FAQ_DUVIDA,
      ref_figma: '9',
      account_context,
      screen: 'FAQ',
      content_name: faq.question,
    })
  }

  const handleBabi = () => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA_FAQ_DUVIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA_FAQ_DUVIDA,
      ref_figma: '9',
      account_context,
      screen: 'FAQ',
      content_name: 'Babi',
    })
    BaseBridge.requestBabi()
  }

  return (
    <>
      <InterUIContainer margin="0px 24px 24px">
        <CommonQuestionsContent>
          {mockCommonQuestions.map((item, index) => (
            <InterUIAccordion
              key={item.id}
              id={`questions-${index}`}
              data-testid={`questions-${index}`}
              header={<PMedium marginBottom="0">{item.question}</PMedium>}
              border="bottom"
              onClick={() => handleClick(item)}
            >
              <PMedium scale={400} marginBottom="0">
                {item.answer}
              </PMedium>
            </InterUIAccordion>
          ))}
        </CommonQuestionsContent>
      </InterUIContainer>
      <InterUISeparator height="8px" margin="48px 0" />
      <InterUIContainer margin="0 24px 24px 24px">
        <InterUIBabi onClick={handleBabi} />
      </InterUIContainer>
    </>
  )
}
