import { useLayoutEffect, useRef, useState } from 'react'

import { PSmall } from 'src/styles/commons'
import { getInputSizeByText, numberFormat } from 'src/utils/commons'
import { useTheme } from 'styled-components'

import { InterUIInputGroup, currencyMask } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { ContentPricing, PrefixPricing } from './BasicCoverage.styles'

interface Props {
  max: number
  price: number
  updateCoveragePrice: (newPrice: number) => void
}

export function BasicCoverage({ max, price, updateCoveragePrice }: Props) {
  const MIN = 50_000
  const theme = useTheme()

  const maxInputLength = numberFormat(max).length
  const inputElEnabled = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState(price)
  const [width, setWidth] = useState('')
  const [feedBackError, setFeedBackError] = useState(false)

  useLayoutEffect(() => {
    const formatValue = numberFormat(value)
    setWidth(getInputSizeByText(formatValue))

    if (value < MIN || value > max) {
      setFeedBackError(true)
    } else {
      setFeedBackError(false)
    }
  }, [value])

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const onlyDigits = Number(target.value.replace(/[^\d]+/g, ''))
    const newPrice = onlyDigits / 100

    if (inputElEnabled.current) {
      currencyMask(inputElEnabled.current, target.value)
    }

    setValue(newPrice)
    updateCoveragePrice(newPrice)

    setWidth(getInputSizeByText(target.value))
  }

  return (
    <>
      <InterUIInputGroup margin="0 0 16px" variant="pricing">
        <ContentPricing error={feedBackError}>
          <PrefixPricing error={feedBackError}>R$ </PrefixPricing>
          <input
            type="text"
            inputMode="numeric"
            maxLength={maxInputLength}
            id="basicCoveragePrice"
            data-testid="basicCoveragePrice"
            value={numberFormat(value)}
            ref={inputElEnabled}
            onChange={handleInputChange}
            placeholder="0,00"
            autoComplete="off"
            style={{ width }}
          />
        </ContentPricing>
        {feedBackError && (
          <PSmall color={theme.colors.feedback.error.A500} data-testid="basicCoverageError">
            {value > max
              ? `Você deve inserir um valor de cobertura até ${formatToBRL(max)}`
              : `Você deve inserir um valor de cobertura de no mínimo ${formatToBRL(MIN)}`}
          </PSmall>
        )}
      </InterUIInputGroup>
    </>
  )
}
