import { Button } from 'src/components'
import { PLarge, PMedium, PSmall } from 'src/styles/commons'

import { formatToBRL } from '@interco/lib-util'

interface ICalculationFooter {
  dataChanged: boolean
  paymentMonthly: number
  disabled: boolean
  handleCalculate: () => void
}

export const CalculationFooter = ({
  dataChanged,
  paymentMonthly,
  disabled,
  handleCalculate,
}: ICalculationFooter) => {
  if (dataChanged)
    return (
      <Button
        data-testid="calculateButton"
        style={{ minWidth: 'auto' }}
        onClick={() => handleCalculate()}
        variant="secondary"
        disabled={disabled}
      >
        <PMedium marginBottom="0">Calcular</PMedium>
      </Button>
    )

  // TODO: tratar o loading do calcular pra não disparar o loading da página
  // if (status === CalculationStatusEnum.SHOW_LOADING)
  //   return (
  //     <div style={{ margin: 'auto' }}>
  //       <InterUILoading size="md" />
  //     </div>
  //   )

  if (!dataChanged)
    return (
      <div>
        <PSmall scale={400}>Valor Total</PSmall>
        <PLarge scale={600} fontWeight="600">
          {formatToBRL(paymentMonthly)}/mês
        </PLarge>
      </div>
    )

  return <></>
}
