import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { ProfessionalActivitiesActions } from 'src/store/ducks/professionalActivities/actions'
import { IProfessionalActivity } from 'src/store/ducks/professionalActivities/types'
import { ContainerFullPage, H2, PMedium, PSmall, SearchContainer } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { LoadProfessionalActivities } from 'src/components/Skeleton/LoadProfessionalActivities/LoadProfessionalActivities'
import { DiscountsActions } from 'src/store/ducks/discounts/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TAGS } from 'src/enums/TaggingEnum'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIAlert,
  InterUIContainer,
  InterUIListDescription,
  InterUIProgressBar,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

import { ErrorMessage } from './ProfessionalActivities.styles'

export function ProfessionalActivities() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(LoadingActions.get)
  const account_context = useSelector(NewRelicActions.getUserContext)

  const iconSearch = <Icons.Search color={theme.colors.primary.A500} className="inter-ic-search" />
  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />

  const searchRef = useRef<HTMLInputElement>(null)
  const [emptySearch, setEmptySearch] = useState(true)
  const [professionalActivitiesView, setProfessionalActivitiesView] = useState<
    IProfessionalActivity[]
  >([])
  const [errorMessage] = useState(false)
  const professionalActivitiesResponse = useSelector(
    ProfessionalActivitiesActions.getProfessionalActivities,
  )
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  useBasePage({
    navbarTitle: PageTitles.FIELD_WORK,
    backNavigationHandler: () => setTimeout(() => history.push(TypesRoutes.ADDRESS), 1),
  })

  useEffect(() => {
    if (Object.keys(professionalActivitiesResponse).length === 0) {
      dispatch(ProfessionalActivitiesActions.getProfessionalActivitiesRequest())
    } else {
      setProfessionalActivitiesView([...professionalActivitiesResponse.professionalActivities])
    }
  }, [professionalActivitiesResponse])

  let debounce: NodeJS.Timeout

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    const search = RemoveAccents(value, true)

    setEmptySearch(!search)

    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (search) {
        const updatedListView = professionalActivitiesResponse.professionalActivities.filter(
          (item) => RemoveAccents(item.activityDescription, true).includes(search),
        )
        setProfessionalActivitiesView(updatedListView)
      } else {
        setProfessionalActivitiesView(professionalActivitiesResponse.professionalActivities)
      }
    }, 150)
  }

  const deleteSearchValue = () => {
    setEmptySearch(true)
    setProfessionalActivitiesView(professionalActivitiesResponse.professionalActivities)

    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  const search = (
    <SearchContainer marginBottom={errorMessage ? '8px' : '24px'}>
      <input
        ref={searchRef}
        data-testid="search"
        type="search"
        name="ProfessionalActivities-search"
        placeholder="Pesquisar"
        autoComplete="off"
        onKeyUp={handleSearch}
        aria-labelledby="ProfessionalActivities-search-button"
      />

      {emptySearch ? (
        iconSearch
      ) : (
        <Icons.Exit
          color={theme.colors.primary.A500}
          className="inter-ic-exit"
          data-testid="deleteSearchValue"
          onClick={deleteSearchValue}
        />
      )}
    </SearchContainer>
  )

  const handleSelectedProfessionalActivities = (
    selectedProfessionalActivity: IProfessionalActivity,
  ) => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '4',
      account_context,
      content_id: selectedProfessionalActivity.activityDescription,
      tela: 'Atividade',
      content_action: 'clique',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })

    dispatch(DiscountsActions.deselectAllDiscounts())
    dispatch(
      QuoteActions.createSimulation({
        history,
        pathname: TypesRoutes.QUOTATION,
        professionalActivity: selectedProfessionalActivity,
      }),
    )
  }

  const renderProfessionalActivitiesList = professionalActivitiesView.map((item, index) => (
    <InterUIListDescription
      key={`ProfessionalActivities-${index.toString()}`}
      id={`ProfessionalActivities-${index.toString()}`}
      data-testid={`ProfessionalActivities-${index.toString()}`}
      iconRight={iconArrow}
      borderBottom
      margin="0 0 16px"
      onClick={() => handleSelectedProfessionalActivities(item)}
      role="group"
    >
      <PMedium marginBottom="0" bold>
        {item.activityDescription}
      </PMedium>
    </InterUIListDescription>
  ))

  const stickyFooter = (
    <InterUIAlert margin="24px">
      <PSmall marginBottom="0">
        O Seguro Empresa Protegida ainda não atende empresas que tenham o armazenamento de produtos
        em depósito como o principal ramo de atividades.
      </PSmall>
    </InterUIAlert>
  )
  return !isLoading ? (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <ContainerFullPage height="140px">
        <InterUIProgressBar progress="50%" />
        <InterUIContainer margin="24px 24px 8px">
          <PSmall marginBottom="24px" scale={300} bold>
            Etapa 2/4
          </PSmall>
          <H2 marginBottom="24px">Qual o ramo de atividade da sua empresa? </H2>
          {search}
          {errorMessage ? (
            <ErrorMessage>
              <PSmall marginBottom="0px" bold>
                Não encontramos o ramo de atividade que você pesquisou. Tente escolher dentre as
                opções da lista de ramos de atividade.
              </PSmall>
            </ErrorMessage>
          ) : (
            <ContainerFullPage height="364px">{renderProfessionalActivitiesList}</ContainerFullPage>
          )}
        </InterUIContainer>
      </ContainerFullPage>
    </InterUIContainer>
  ) : (
    <ContainerFullPage height="0px" margin="0" overflowHidden>
      <LoadProfessionalActivities />
    </ContainerFullPage>
  )
}
