import BaseService from 'src/config/bridge/BaseService'
import { ICreateSimulationRequest, IQuote } from 'src/store/ducks/quote/types'
import { ICoverage } from 'src/store/ducks/coverages/types'
import { IDiscount } from 'src/store/ducks/discounts/types'
import { IProfessionalActivity } from 'src/store/ducks/professionalActivities/types'

import { MockService } from './mockService'

export default class QuoteService {
  static getQuotes(account: string) {
    const headers = {
      bankAccount: account,
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IQuote[]>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/quotes`,
      headers,
    })
  }

  static createQuote(account: string) {
    const headers = {
      bankAccount: account,
    }

    return BaseService.doExecute<IQuote>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/quotes`,
      headers,
    })
  }

  static createSimulation(
    account: string,
    quoteId: string,
    professionalActivity: ICreateSimulationRequest,
  ) {
    const headers = {
      bankAccount: account,
      professionalActivity: String(professionalActivity.professionalActivity.id),
      quoteId,
    }

    const data = professionalActivity as unknown as Record<string, string>

    return BaseService.doExecute<IQuote>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/simulations`,
      headers,
      data,
    })
  }

  static calculateSimulation(
    account: string,
    quoteId: string,
    coverages: ICoverage[],
    discounts: IDiscount[],
    professionalActivity: IProfessionalActivity,
  ) {
    const headers = {
      bankAccount: account,
      quoteId,
    }

    const body = {
      coverages,
      discounts,
      professionalActivity,
    }

    const data = body as unknown as Record<string, string>

    return BaseService.doExecute<IQuote>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/simulations`,
      headers,
      data,
    })
  }
}
