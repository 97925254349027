import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PageDirections } from 'src/routes/pageDirections'
import { NavbarActions } from 'src/store/ducks/navbar/actions'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUINavbar } from '@interco/inter-ui-react-lib'

import { BtnRight } from './Navbar.styles'

export function Navbar() {
  const theme = useTheme()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const title = useSelector(NavbarActions.getTitle)
  const toggleFaqBtn = useSelector(NavbarActions.getToggleFaqBtn)
  const toggleHomeBtn = useSelector(NavbarActions.getToggleHomeBtn)
  const backNavigation = useSelector(NavbarActions.getBackNavigationHandler)
  const toggleBackNavigationBtn = useSelector(NavbarActions.getToggleBackNavigationBtn)

  const isInitialPage = (path: string) => {
    const initialPage = [TypesRoutes.START]

    return initialPage.find((page) => page === path) !== undefined
  }

  const onClickedBack = () => {
    dispatch(NavbarActions.setDirection(PageDirections.BACKWARD))
    if (isInitialPage(location.pathname)) {
      BaseBridge.requestGoBack()
    } else {
      backNavigation()
    }
    setTimeout(() => {
      dispatch(NavbarActions.setDirection(PageDirections.FORWARD))
    }, 550)
  }

  const iconRightBtn = (
    <>
      {toggleFaqBtn && (
        <BtnRight
          aria-label="Botão FAQ"
          data-testid="test-buttonFaq"
          onClick={() => history.push(TypesRoutes.COMMON_QUESTIONS)}
        >
          <Icons.Help color={theme.colors.primary.A500} width={24} height={24} />
        </BtnRight>
      )}
      {toggleHomeBtn && (
        <BtnRight
          aria-label="Botão home"
          data-testid="test-buttonHome"
          onClick={() => BaseBridge.requestGoBack()}
        >
          <Icons.Home color={theme.colors.primary.A500} width={24} height={24} />
        </BtnRight>
      )}
    </>
  )

  return (
    <>
      <InterUINavbar
        title={title}
        iconRight={iconRightBtn}
        hideBack={!toggleBackNavigationBtn}
        onClickedBack={onClickedBack}
        data-testid="navbar-container"
      />
    </>
  )
}
