import { Reducer } from 'redux'

import {
  IProfessionalActivitiesResponse,
  ProfessionalActivitiesState,
  ProfessionalActivitiesTypes,
} from './types'

const INITIAL_STATE: ProfessionalActivitiesState = {
  professionalActivities: {} as IProfessionalActivitiesResponse,
}

const reducer: Reducer<ProfessionalActivitiesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProfessionalActivitiesTypes.SET_PROFESSIONAL_ACTIVITIES:
      return { ...state, professionalActivities: action.payload }

    default:
      return state
  }
}
export default reducer
