import { InterUIContainer, InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export function LoadQuotation() {
  return (
    <SkeletonContent data-testid="loading-quotation">
      <InterUIContainer margin="0">
        <InterUIContainer margin="52px 24px 24px">
          <SkeletonContent>
            <InterUISkeleton height="44px" marginBottom="24px" />
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="80px" height="40px" marginBottom="0" />
            </SkeletonGrid>
            <SkeletonContent>
              <InterUISkeleton width="160px" height="40px" marginBottom="20px" />
              <InterUISkeleton height="52px" marginBottom="20px" />
              <InterUISkeleton width="120px" height="40px" />
            </SkeletonContent>
          </SkeletonContent>

          <InterUISeparator height="8px" margin="32px 0" />

          <SkeletonContent>
            <SkeletonContent>
              <InterUISkeleton height="72px" marginBottom="24px" />
            </SkeletonContent>
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="32px" height="32px" marginBottom="0" />
            </SkeletonGrid>
          </SkeletonContent>
        </InterUIContainer>
      </InterUIContainer>
    </SkeletonContent>
  )
}
