import BaseService from 'src/config/bridge/BaseService'
import { IProfessionalActivitiesResponse } from 'src/store/ducks/professionalActivities/types'

import { MockService } from './mockService'

export default class ProfessionalActivitiesService {
  static getProfessionalActivities() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IProfessionalActivitiesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/professional-activity?size=500`,
      headers,
    })
  }
}
