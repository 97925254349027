import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { NavbarActions } from '../store/ducks/navbar/actions'
import { scrollToTop } from '../utils/commons'
import { mixedRoutes } from './mixedRoutes/mixedRoutes'
import './transition-style.css'

const Routes = () => {
  const goTo = useSelector(NavbarActions.getDirection)

  const [direction, setDirection] = useState('pageSliderLeft')

  useEffect(() => {
    setDirection(goTo === 'backward' ? 'pageSliderRight' : 'pageSliderLeft')
  }, [goTo])

  const scrollTop = () => {
    scrollToTop()
  }

  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            onEnter={scrollTop}
            timeout={500}
            key={location.key}
            classNames={direction}
          >
            <Switch location={location}>
              {mixedRoutes.map((page) => (
                <Route key={location.pathname} path={page.route} exact component={page.component} />
              ))}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    />
  )
}

export default withRouter(Routes)
