import styled, { css } from 'styled-components'

export const Collapse = styled.div<{ toggle: boolean; maxHeight: string }>`
  max-height: ${(props) => props.maxHeight};
  transition: max-height 0.6s linear;
  overflow: hidden;
  ${(props) => props.toggle && collapsedStyle}
`

export const collapsedStyle = css`
  height: auto;
  max-height: 100vh;
`

export const ShowMoreBtn = styled.button<{ toggle: boolean }>`
  display: flex;
  align-items: center;
  margin: auto;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    transition: all 0.5s;

    ${(props) => props.toggle && `transform: rotate(180deg);`}
  }
`
