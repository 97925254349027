import { SagaWithHistory } from '../utils/providerSaga'

export enum AddressTypes {
  UPDATE_ADDRESS = `@address/UPDATE_ADDRESS`,
  GET_ADDRESS_BY_CEP = `@address/GET_ADDRESS_BY_CEP`,
  SET_SEARCHED_ADDRESS = `@address/SET_SEARCHED_ADDRESS`,
}

export interface IAddress {
  cnpj: string
  companyName: string
  zipCode: string
  streetName: string
  streetNumber?: string
  streetExtraInformation?: string
  districtName: string
  city: string
  state: string
}

export interface ISearchAddress {
  zipCode: string
  city: string
  typeAddress: string
  address: string
  neighborhood: string
  referencePoint: string
  district: string
}

export interface ISearchAddressRequest {
  zipCode: string
  syncActionWithSaga: (data: ISearchAddress) => void
}

export interface IUpdateAddressRequest extends SagaWithHistory {
  address: IAddress
}

export interface AddressState {
  readonly searchedAddress: ISearchAddress
}
