import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { IInspectionContact, InspectionContactTypes } from './types'

const getSelectContact = (state: RootState) => state.inspectionContact.selectedContact

const setSelectContact = (data: IInspectionContact) =>
  action(InspectionContactTypes.setSelectContact, data)

export const InspectionContactActions = {
  getSelectContact,
  setSelectContact,
}
