import { action } from 'typesafe-actions'

import { NavbarTypes } from './types'
import { RootState } from '../..'
import { PageTitles } from '../../../enums/pageTitles'
import { PageDirections } from '../../../routes/pageDirections'

const getTitle = (state: RootState) => state.navbar.title

const setTitle = (data: PageTitles) => action(NavbarTypes.SET_TITLE, data)

const showFaqBtn = () => action(NavbarTypes.TOGGLE_FAQ_BTN, true)

const hideFaqBtn = () => action(NavbarTypes.TOGGLE_FAQ_BTN, false)

const getToggleFaqBtn = (state: RootState) => state.navbar.toggleFaqBtn

const showHomeBtn = () => action(NavbarTypes.TOGGLE_HOME_BTN, true)

const hideHomeBtn = () => action(NavbarTypes.TOGGLE_HOME_BTN, false)

const getToggleHomeBtn = (state: RootState) => state.navbar.toggleHomeBtn

const setDirection = (direction: PageDirections) => action(NavbarTypes.SET_DIRECTION, direction)

const getDirection = (state: RootState) => state.navbar.direction

const setBackNavigationHandler = (cb: () => void) =>
  action(NavbarTypes.SET_BACK_NAVIGATION_HANDLER, cb)

const getBackNavigationHandler = (state: RootState) => state.navbar.backNavigationHandler

const showBackNavigationBtn = () => action(NavbarTypes.TOGGLE_BACK_NAVIGATION_BTN, true)

const hideBackNavigationBtn = () => action(NavbarTypes.TOGGLE_BACK_NAVIGATION_BTN, false)

const getToggleBackNavigationBtn = (state: RootState) => state.navbar.toggleBackNavigationBtn

export const NavbarActions = {
  getTitle,
  setTitle,
  showFaqBtn,
  hideFaqBtn,
  showHomeBtn,
  hideHomeBtn,
  getToggleFaqBtn,
  getToggleHomeBtn,
  setDirection,
  getDirection,
  setBackNavigationHandler,
  getBackNavigationHandler,
  hideBackNavigationBtn,
  showBackNavigationBtn,
  getToggleBackNavigationBtn,
}
