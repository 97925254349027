import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { SagaWithHistory } from '../utils/providerSaga'
import { DiscountsTypes, IDiscount } from './types'

const getDiscountsRequest = (data?: SagaWithHistory) =>
  action(DiscountsTypes.GET_DISCOUNTS_REQUEST, data)

const getDiscounts = (state: RootState) => state.discounts.discounts

const setDiscounts = (data: IDiscount[]) => action(DiscountsTypes.SET_DISCOUNTS, data)

const deselectAllDiscounts = () => action(DiscountsTypes.DESELECT_ALL_DISCOUNTS)

export const DiscountsActions = {
  getDiscounts,
  getDiscountsRequest,
  setDiscounts,
  deselectAllDiscounts,
}
