import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { PMedium, PSmall } from 'src/styles/commons'
import { Button } from 'src/components'
import { getInputSizeByText, numberFormat } from 'src/utils/commons'
import { useTheme } from 'styled-components'
import { ICoverage, MIN_ADDITIONAL_COVERAGE } from 'src/store/ducks/coverages/types'

import {
  InterUIBottomSheet,
  InterUIInputGroup,
  InterUISlider,
  currencyMask,
} from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { ContentPricing, PrefixPricing } from './AdditionalCoveragePriceBS.styles'

interface Props {
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
  coverage: ICoverage
  basicCoverageValue: number
  updateCoverage: (libertyId: string, toggle: boolean, value: number) => void
}

export function AdditionalCoveragePriceBS({
  toggleState,
  coverage,
  basicCoverageValue,
  updateCoverage,
}: Props) {
  const MIN_VALUE = MIN_ADDITIONAL_COVERAGE
  const inputElEnabled = useRef<HTMLInputElement>(null)

  const theme = useTheme()
  const [toggleBS, setToggleBS] = toggleState
  const [coverageValue, setCoverageValue] = useState<number>(MIN_VALUE)
  const [width, setWidth] = useState('')
  const [feedBackError, setFeedBackError] = useState(false)

  const calculateMax = () => {
    const maxByPercentage = (coverage?.maximumInsuredPercentage ?? 0.3) * basicCoverageValue
    return coverage.maximumInsuredAmount && coverage.maximumInsuredAmount < maxByPercentage
      ? coverage.maximumInsuredAmount
      : maxByPercentage
  }
  const maxInputLength = calculateMax()

  useEffect(() => {
    setCoverageValue(coverage?.insuredAmount ?? MIN_VALUE)
  }, [coverage, basicCoverageValue])

  useLayoutEffect(() => {
    const formatValue = numberFormat(coverageValue)
    setWidth(getInputSizeByText(formatValue))

    if (coverageValue < MIN_VALUE || coverageValue > maxInputLength) {
      setFeedBackError(true)
    } else {
      setFeedBackError(false)
    }
  }, [coverageValue])

  const onChangeCoverageValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setCoverageValue(Number(ev.target.value))
  }

  /**
   * Lida com a mudança do Input.
   * Aplica a máscara monetária no elemento input informado.
   * Seta o Value passando os dígitos inseridos no Input.
   * Seta a largura do Input.
   * @param target propriedade do elemento input informado.
   */
  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const onlyDigits = Number(target.value.replace(/[^\d]+/g, ''))

    if (inputElEnabled.current) {
      currencyMask(inputElEnabled.current, target.value)
    }

    setCoverageValue(onlyDigits / 100)
  }

  const handleSelect = () => {
    updateCoverage(coverage.libertyId, true, coverageValue)
    closeBottomSheet()
  }

  const closeBottomSheet = () => {
    setToggleBS(false)
  }

  return (
    <InterUIBottomSheet
      data-testid="coveragePriceBS"
      color={theme.colors.neutral.grayscale.A500} // TODO: não funciona?
      title="Qual será o valor dessa cobertura adicional?"
      toggle={toggleBS}
      onHide={closeBottomSheet}
    >
      <InterUIInputGroup style={{ height: '74px' }} variant="pricing">
        <ContentPricing>
          <PrefixPricing>R$ </PrefixPricing>
          <input
            type="text"
            inputMode="numeric"
            maxLength={maxInputLength}
            name="input-pricing"
            id="input-pricing"
            data-testid="test-input-pricing"
            value={numberFormat(coverageValue)}
            ref={inputElEnabled}
            onChange={handleInputChange}
            placeholder="0,00"
            autoComplete="off"
            style={{ width }}
          />
        </ContentPricing>
        {feedBackError && (
          <PSmall
            margin="0px"
            color={theme.colors.feedback.error.A500}
            textAlign="center"
            data-testid="test-feedback-error"
          >
            Valor{' '}
            {coverageValue > maxInputLength
              ? `máximo: ${formatToBRL(maxInputLength)}`
              : `mínimo: ${formatToBRL(MIN_VALUE)}`}
          </PSmall>
        )}
      </InterUIInputGroup>
      <InterUISlider
        variant="between"
        id="coverage-slider"
        data-testid="coverage-slider"
        min={MIN_VALUE}
        max={maxInputLength}
        leftText={<PSmall scale={400}>{formatToBRL(MIN_VALUE)}</PSmall>}
        rightText={<PSmall scale={400}>{formatToBRL(maxInputLength)}</PSmall>}
        onChange={onChangeCoverageValue}
        value={coverageValue}
      />

      <Button
        data-testid="button-continue-CoveragePriceBS"
        margin="52px 0 0"
        variant="primary"
        onClick={handleSelect}
        disabled={feedBackError}
      >
        <PMedium marginBottom="0">Selecionar</PMedium>
      </Button>
    </InterUIBottomSheet>
  )
}
