import styled from 'styled-components'

export const GroupIcons = styled.div`
  display: flex;
  column-gap: 32px;
`

export const ActionsIcons = styled.div`
  max-width: 60px;
  text-align: center;

  &.disabled {
    pointer-events: none;
    color: ${(props) => props.theme.colors.neutral.grayscale.A200};

    div {
      background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
    }

    path {
      stroke: ${(props) => props.theme.colors.neutral.grayscale.A200};
    }
  }
`

export const ContentIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.primary.A100};
  border-radius: 100%;
`
