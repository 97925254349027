import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const KnowMore = styled(Link)`
  color: ${(props) => props.theme.colors.primary.A500};
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.063rem;
  text-decoration: none;
`
export const QuotationFooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 16px;
`
export const CoverageContainer = styled.div`
  margin-bottom: 24px;
`

export const CoverageSwitchBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const EditPriceBox = styled.div`
  margin-top: 12px;
  position: relative;

  svg {
    position: absolute;
    bottom: 0px;
    left: 156px;
  }
`

export const DeductiblePrice = styled.span`
  font-weight: 700;
`
