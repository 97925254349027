import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import AddressService from 'src/services/AddressService'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { LoadingActions } from '../loading/actions'
import { ProfessionalActivitiesActions } from '../professionalActivities/actions'
import { QuoteActions } from '../quote/actions'
import { errorHandlingSaga, redirectThroughSaga } from '../utils/providerSaga'
import { AddressActions } from './actions'
import { AddressTypes, ISearchAddress } from './types'

function* getAddressByCep({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { zipCode, syncActionWithSaga } = payload

    const response = yield* call(AddressService.getAddressByZipCode, zipCode)

    let addressData: ISearchAddress

    if (response.status === 204) {
      addressData = {} as ISearchAddress
    } else {
      addressData = response.object
    }

    yield* put(AddressActions.setSearchedAddress(addressData))

    if (syncActionWithSaga) {
      yield* call(syncActionWithSaga, addressData)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AddressActionsSaga.getAddressByCep')
  }
}

function* updateAddress({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { address } = payload

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    let currentQuote = yield* select(QuoteActions.getCurrentQuote)

    const updateAddressResponse = yield* call(
      AddressService.updateAddress,
      userInfoBridge.account,
      currentQuote.id,
      address,
    )

    const data = updateAddressResponse.object

    currentQuote = {
      ...currentQuote,
      cnpj: data.cnpj,
      companyName: data.companyName,
      zipCode: data.zipCode,
      streetName: data.streetName,
      streetNumber: data.streetNumber ?? '',
      streetExtraInformation: data.streetExtraInformation ?? '',
      districtName: data.districtName,
      city: data.city,
      state: data.state,
    }

    yield* put(QuoteActions.setCurrentQuote(currentQuote))

    yield* put(ProfessionalActivitiesActions.getProfessionalActivitiesRequest())

    yield* put(AddressActions.setSearchedAddress({} as ISearchAddress))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AddressActionsSaga.updateAddress')
  }
}

export default function* watchAddress() {
  yield* takeLatest(AddressTypes.GET_ADDRESS_BY_CEP, getAddressByCep)
  yield* takeLatest(AddressTypes.UPDATE_ADDRESS, updateAddress)
}
