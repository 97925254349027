import { all } from 'typed-redux-saga'

import watchAddress from './address/saga'
import watchDiscounts from './discounts/saga'
import watchCoverages from './coverages/saga'
import watchDocuments from './document/saga'
import watchProfessionalActivities from './professionalActivities/saga'
import watchQuotes from './quote/saga'
import watchHires from './hire/saga'

export default function* rootSaga() {
  return yield* all([
    watchAddress(),
    watchDocuments(),
    watchQuotes(),
    watchHires(),
    watchProfessionalActivities(),
    watchDiscounts(),
    watchCoverages(),
  ])
}
