import { Dispatch, SetStateAction } from 'react'

import { H3, PMedium, WarningSign } from 'src/styles/commons'
import { Button } from 'src/components'
import { useTheme } from 'styled-components'
import { Icons } from 'src/utils/icons'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'

import { WarningContainer } from './MinAdditionalCoveragesBS.styles'

interface Props {
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
}

export function MinAdditionalCoveragesBS({ toggleState }: Props) {
  const theme = useTheme()
  const [toggleBS, setToggleBS] = toggleState

  const attentionLG = (
    <Icons.Attention height={32} width={32} color={theme.colors.feedback.warning.A500} />
  )

  const closeBottomSheet = () => {
    setToggleBS(false)
  }

  return (
    <InterUIBottomSheet
      data-testid="minCoveragePriceBS"
      toggle={toggleBS}
      onHide={closeBottomSheet}
    >
      <WarningContainer>
        <WarningSign>{attentionLG}</WarningSign>
      </WarningContainer>
      <H3 margin="40px 0 16px">Selecione as coberturas adicionais</H3>

      <PMedium scale={400} marginBottom="24px">
        Para continuar, você deve selecionar uma ou mais coberturas adicionais para o seu seguro.
      </PMedium>
      <Button
        data-testid="continueButtonCoveragePriceBS"
        margin="24px 0 0"
        variant="primary"
        onClick={closeBottomSheet}
      >
        Escolha as coberturas
      </Button>
    </InterUIBottomSheet>
  )
}
