import { Reducer } from 'redux'

import { IInspectionContact, InspectionContactState, InspectionContactTypes } from './types'

const INITIAL_STATE: InspectionContactState = {
  selectedContact: {} as IInspectionContact,
}

const reducer: Reducer<InspectionContactState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InspectionContactTypes.setSelectContact:
      return { ...state, selectedContact: action.payload }

    default:
      return state
  }
}

export default reducer
