import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { NewRelicTypes } from './types'

const getUserContext = (state: RootState) => state.newRelic.context

const setUserContext = (data: string) => action(NewRelicTypes.SET_USER_CONTEXT, data)

export const NewRelicActions = {
  getUserContext,
  setUserContext,
}
