import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { MenuCheckout, Ombudsman, QuickAccessBar, SubscriptionDetails } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { AfterSalesEnum } from 'src/enums/AfterSalesEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { HireActions } from 'src/store/ducks/hire/actions'
import { ContainerFullPage, PXSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { CoveragesActions } from 'src/store/ducks/coverages/actions'
import { LibertyIdEnum } from 'src/store/ducks/coverages/types'
import { TAGS } from 'src/enums/TaggingEnum'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIBabi,
  InterUIBox,
  InterUIContainer,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { Collapse, ShowMoreBtn } from './AfterSalesStatus.style'

export interface ITagAfterSales {
  content_id?: string
  content_action?: string
}

export function AfterSalesStatus() {
  const theme = useTheme()

  const hiredQuote = useSelector(HireActions.getHiredQuote)
  const currentQuote = useSelector(QuoteActions.getCurrentQuote)
  const basicCoverageDetail = useSelector(CoveragesActions.getBasicCoverageDetail)

  const account_context = useSelector(NewRelicActions.getUserContext)

  const coverages = hiredQuote.Coverages.map((cov) => {
    if (cov.libertyId === LibertyIdEnum.BASICA) {
      cov.coverageDescription = basicCoverageDetail
    }

    return cov
  })

  useBasePage({
    navbarTitle: PageTitles.PROTECTED,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  const chooseStatus: { [status: string]: string[] } = {
    [AfterSalesEnum.IN_ANALYSIS]: ['12', 'Em análise'],
    [AfterSalesEnum.ACTIVE]: ['13', 'Ativo'],
    [AfterSalesEnum.NOT_APPROVED]: ['14', 'Não aprovado'],
    [AfterSalesEnum.CANCELED]: ['14', 'Cancelado'],
    [AfterSalesEnum.FINISHED]: ['14', 'Finalizado'],
  }

  const newRelicInfo = {
    ref_figma: chooseStatus[hiredQuote.status][0],
    status: chooseStatus[hiredQuote.status][1],
    account_context,
  }

  const tagAfterSales = ({ content_id, content_action }: ITagAfterSales) => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: newRelicInfo.ref_figma,
      account_context,
      status: newRelicInfo.status,
      content_id: content_id ?? 'Pós venda',
      tela: 'Pós venda',
      content_action: content_action ?? 'tela carregada',
    })
  }

  useEffect(() => {
    tagAfterSales({})
  }, [])

  const handleBabi = () => {
    tagAfterSales({ content_id: 'Babi', content_action: 'clique' })
    BaseBridge.requestBabi()
  }

  const iconSize = 24
  const colorPrimary = theme.colors.primary.A500

  const iconArrow = <Icons.ChevronDown width={iconSize} height={iconSize} color={colorPrimary} />

  const [toggleShowMore, setToggleShowMore] = useState(false)

  const collapseHeight: { [status: string]: string } = {
    [AfterSalesEnum.IN_ANALYSIS]: '156px',
    [AfterSalesEnum.ACTIVE]: '156px',
    [AfterSalesEnum.NOT_APPROVED]: '80px',
    [AfterSalesEnum.CANCELED]: '160px',
    [AfterSalesEnum.FINISHED]: '160px',
  }

  return (
    <ContainerFullPage margin="0" height="0px">
      <InterUIContainer margin="24px 24px 32px">
        <InterUIBox direction="column" align="stretch" margin="0 0 24px">
          <Collapse toggle={toggleShowMore} maxHeight={collapseHeight[hiredQuote.status]}>
            <SubscriptionDetails
              status={hiredQuote.status}
              afterSalesInfo={hiredQuote}
              cnpj={currentQuote.cnpj}
              handleTag={tagAfterSales}
            />
          </Collapse>

          <ShowMoreBtn toggle={toggleShowMore} onClick={() => setToggleShowMore(!toggleShowMore)}>
            <PXSmall marginBottom="0" bold color={colorPrimary}>
              {!toggleShowMore ? 'Mostrar mais' : 'Mostrar menos'}
            </PXSmall>
            {iconArrow}
          </ShowMoreBtn>
        </InterUIBox>
        <QuickAccessBar newRelicInfo={newRelicInfo} status={hiredQuote.status} />
      </InterUIContainer>

      <InterUISeparator height="8px" />

      <InterUIContainer margin="32px 24px">
        {hiredQuote.status === AfterSalesEnum.FINISHED ||
        hiredQuote.status === AfterSalesEnum.NOT_APPROVED ? (
          <Ombudsman />
        ) : (
          <MenuCheckout status={hiredQuote.status} coverages={coverages} />
        )}
      </InterUIContainer>
      <InterUISeparator height="8px" margin="48px 0" />
      <InterUIContainer margin="0 24px 24px 24px">
        <InterUIBabi onClick={handleBabi} />
      </InterUIContainer>
    </ContainerFullPage>
  )
}
