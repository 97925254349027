import React, { useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { mockDetailsCoverages } from 'src/mocks/Quotation'
import { ContainerFullPage, H2, PMedium } from 'src/styles/commons'
import { TAGS } from 'src/enums/TaggingEnum'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { useSelector } from 'react-redux'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { Button } from '../Button/Button'
import { Coverages } from './CoverageDetails.styles'

export function CoverageDetails() {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const account_context = useSelector(NewRelicActions.getUserContext)

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '16',
      account_context,
      content_id: renderDetails?.title ?? '',
      tela: 'Sabe mais',
      content_action: 'tela carregada',
    })
  }, [])

  useBasePage({
    navbarTitle: PageTitles.COVERAGE_DETAILS,
    backNavigationHandler: history.goBack,
  })

  const renderDetails = mockDetailsCoverages.find((params) => params.id === id)

  const renderListCoverages = (
    <>
      <H2 margin="24px 0px 32px">{renderDetails?.title}</H2>
      <PMedium bold marginBottom="24px">
        {renderDetails?.coverages.label}
      </PMedium>
      <Coverages>
        {renderDetails?.coverages.list.map((item, index) => {
          const indexId = `list-${index}`
          return (
            <li key={indexId} id={indexId} data-testid={indexId}>
              {item.description}

              {item.subDescription && (
                <PMedium margin="16px 0px 0px">{item.subDescription}</PMedium>
              )}
            </li>
          )
        })}
      </Coverages>

      {renderDetails?.coveragesInsurance && (
        <>
          <PMedium bold margin="32px 0 24px">
            {renderDetails?.coveragesInsurance.label}
          </PMedium>
          <Coverages>
            {renderDetails?.coveragesInsurance.list.map((item, index) => {
              const indexId = `list-insurance-${index}`
              return (
                <li key={indexId} id={indexId} data-testid={indexId}>
                  {item.description}
                </li>
              )
            })}
          </Coverages>
        </>
      )}
    </>
  )

  const stickyFooter = (
    <Button data-testid="button-continue" margin="24px" onClick={() => history.goBack()}>
      Voltar
    </Button>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <ContainerFullPage margin="0 24px">{renderListCoverages}</ContainerFullPage>
    </InterUIContainer>
  )
}
