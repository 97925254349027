import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'typed-redux-saga'
import ProfessionalActivitiesService from 'src/services/ProfessionalActivities'

import { LoadingActions } from '../loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from '../utils/providerSaga'
import { ProfessionalActivitiesActions } from './actions'
import { ProfessionalActivitiesTypes } from './types'

function* getProfessionalActivities({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const professionalActivitiesResponse = yield* call(
      ProfessionalActivitiesService.getProfessionalActivities,
    )

    yield* put(
      ProfessionalActivitiesActions.setProfessionalActivities(
        professionalActivitiesResponse.object,
      ),
    )

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
    }

    yield* errorHandlingSaga(
      error as Error,
      'ProfessionalActivitiesActions.getProfessionalActivities',
      errorDetails,
    )
  }
}

export default function* watchProfessionalActivities() {
  yield* takeLatest(
    ProfessionalActivitiesTypes.GET_PROFESSIONAL_ACTIVITIES_REQUEST,
    getProfessionalActivities,
  )
}
