import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { SagaWithHistory } from '../utils/providerSaga'
import { IProfessionalActivitiesResponse, ProfessionalActivitiesTypes } from './types'

const getProfessionalActivitiesRequest = (data?: SagaWithHistory) =>
  action(ProfessionalActivitiesTypes.GET_PROFESSIONAL_ACTIVITIES_REQUEST, data)

const getProfessionalActivities = (state: RootState) =>
  state.professionalActivities.professionalActivities

const setProfessionalActivities = (data: IProfessionalActivitiesResponse) =>
  action(ProfessionalActivitiesTypes.SET_PROFESSIONAL_ACTIVITIES, data)

export const ProfessionalActivitiesActions = {
  getProfessionalActivities,
  getProfessionalActivitiesRequest,
  setProfessionalActivities,
}
