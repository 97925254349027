import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { QuoteStatusEnum } from 'src/enums/QuoteStatusEnum'
import QuoteService from 'src/services/QuoteService'
import { sortById } from 'src/utils/commons'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'

import { LoadingActions } from '../loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from '../utils/providerSaga'
import { QuoteActions } from './actions'
import { ICreateSimulationRequest, IQuotesRequest, QuoteTypes } from './types'
import { CoveragesActions } from '../coverages/actions'

function* getQuotes({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { onTransmission } = (payload || {}) as IQuotesRequest

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    const quotesResponse = yield* call(QuoteService.getQuotes, userInfoBridge.account)

    yield* put(QuoteActions.setQuotes(quotesResponse.object))

    const quotes = quotesResponse.object

    if (quotes.length > 0) {
      quotes.sort(sortById())
      const currentQuote = quotes[quotes.length - 1]

      onTransmission(currentQuote.status)
      yield* put(QuoteActions.setCurrentQuote(currentQuote))
    } else {
      onTransmission(QuoteStatusEnum.NO_QUOTES)
    }

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
      route: TypesRoutes.START,
    }

    yield* errorHandlingSaga(error as Error, 'QuoteActions.getQuotes', errorDetails)
  }
}

function* createQuote({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    const quoteResponse = yield* call(QuoteService.createQuote, userInfoBridge.account)

    yield* put(QuoteActions.setCurrentQuote(quoteResponse.object))

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
    }

    yield* errorHandlingSaga(error as Error, 'QuoteActions.createQuote', errorDetails)
  }
}

function* createSimulation({ payload }: AnyAction) {
  try {
    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.show())

    const { professionalActivity } = payload

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    const { id } = yield* select(QuoteActions.getCurrentQuote)

    const quoteId = String(id)

    const prof: ICreateSimulationRequest = {
      professionalActivity,
    }

    const createSimulationResponse = yield* call(
      QuoteService.createSimulation,
      userInfoBridge.account,
      quoteId,
      prof,
    )

    yield* put(QuoteActions.setCurrentQuote(createSimulationResponse.object))

    yield* put(CoveragesActions.handleSimulationCoverages())

    yield* put(LoadingActions.hide())
  } catch (error) {
    console.log(error)
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
    }

    yield* errorHandlingSaga(error as Error, 'QuoteActions.createSimulation', errorDetails)
  }
}

function* calculateSimulation({ payload }: AnyAction) {
  try {
    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.show())

    const { coverages, discounts, professionalActivity } = payload

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    const { id } = yield* select(QuoteActions.getCurrentQuote)

    const quoteId = String(id)

    const calculateSimulationResponse = yield* call(
      QuoteService.calculateSimulation,
      userInfoBridge.account,
      quoteId,
      coverages,
      discounts,
      professionalActivity,
    )

    yield* put(QuoteActions.setCurrentQuote(calculateSimulationResponse.object))
    yield* put(CoveragesActions.handleSimulationCoverages())

    yield* put(LoadingActions.hide())
  } catch (error) {
    console.log(error)
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
      route: TypesRoutes.QUOTATION,
    }

    yield* errorHandlingSaga(error as Error, 'QuoteActions.calculateSimulation', errorDetails)
  }
}

export default function* watchQuotes() {
  yield* takeLatest(QuoteTypes.CREATE_QUOTE, createQuote)
  yield* takeLatest(QuoteTypes.CREATE_SIMULATION, createSimulation)
  yield* takeLatest(QuoteTypes.CALCULATE_SIMULATION, calculateSimulation)
  yield* takeLatest(QuoteTypes.GET_QUOTES_REQUEST, getQuotes)
}
