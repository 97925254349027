import { RootState } from 'src/store'
import { action } from 'typesafe-actions'

import { SagaWithHistory } from '../utils/providerSaga'
import { CoveragesTypes, ICoverage } from './types'

const getCoveragesRequest = (data?: SagaWithHistory) =>
  action(CoveragesTypes.GET_COVERAGES_REQUEST, data)

const getCoverages = (state: RootState) => state.coverages.coverages

const setCoverages = (data: ICoverage[]) => action(CoveragesTypes.SET_COVERAGES, data)

const getSimulationCoverages = (state: RootState) => state.coverages.simulationCoverages

const setSimulationCoverages = (data: ICoverage[]) =>
  action(CoveragesTypes.SET_SIMULATION_COVERAGES, data)

const getBasicCoverageDetail = (state: RootState) => state.coverages.basicCoverageDetail

const setBasicCoverageDetail = (data: string) =>
  action(CoveragesTypes.SET_BASIC_COVERAGE_DETAILS, data)

const handleSimulationCoverages = () => action(CoveragesTypes.HANDLE_SIMULATION_COVERAGES)

export const CoveragesActions = {
  getCoverages,
  getCoveragesRequest,
  setCoverages,
  getSimulationCoverages,
  setSimulationCoverages,
  handleSimulationCoverages,
  getBasicCoverageDetail,
  setBasicCoverageDetail,
}
