import { Reducer } from 'redux'

import { AddressState, AddressTypes, ISearchAddress } from './types'

const INITIAL_STATE: AddressState = {
  searchedAddress: {} as ISearchAddress,
}

const reducer: Reducer<AddressState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AddressTypes.SET_SEARCHED_ADDRESS:
      return { ...state, searchedAddress: action.payload }
    default:
      return state
  }
}

export default reducer
