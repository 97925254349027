import Search from 'inter-frontend-svgs/lib/orangeds/MD/search'
import Exit from 'inter-frontend-svgs/lib/orangeds/MD/exit'

import Attention from '@interco/icons/orangeds/LG/attention'
import Apps from '@interco/icons/orangeds/MD/apps'
import BookOpen from '@interco/icons/orangeds/MD/book-open'
import Canceled from '@interco/icons/orangeds/MD/canceled'
import ChevronDown from '@interco/icons/orangeds/MD/chevron-down'
import ComputerCall from '@interco/icons/orangeds/MD/computer-call'
import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import Edit from '@interco/icons/orangeds/MD/edit'
import Email from '@interco/icons/orangeds/MD/email'
import Info from '@interco/icons/orangeds/MD/info'
import Help from '@interco/icons/orangeds/MD/help'
import Home from '@interco/icons/orangeds/MD/home'
import PdfFile from '@interco/icons/orangeds/MD/pdf-file'
import Phone from '@interco/icons/orangeds/MD/phone'
import PhoneCall from '@interco/icons/orangeds/MD/phone-call'
import Receipt from '@interco/icons/orangeds/MD/receipt'
import Services from '@interco/icons/orangeds/MD/services'
import Card from '@interco/icons/orangeds/MD/card'
import CheckCircle from '@interco/icons/orangeds/LG/check-circle'
import CheckFill from '@interco/icons/orangeds/SM/check-fill'
import Heart from '@interco/icons/orangeds/SM/heart'
import Market from '@interco/icons/orangeds/SM/market'
import DigitalAccount from '@interco/icons/orangeds/SM/digital-account'
import Credit from '@interco/icons/orangeds/MD/credit'
import NotificationCenter from '@interco/icons/orangeds/MD/notification-center'
import Wallet from '@interco/icons/orangeds/MD/wallet'
import Dollar from '@interco/icons/orangeds/MD/dollar'

export const Icons = {
  Apps,
  Attention,
  BookOpen,
  Card,
  Canceled,
  ChevronRight,
  ChevronDown,
  ComputerCall,
  Credit,
  Edit,
  Email,
  Info,
  Help,
  Home,
  PdfFile,
  Phone,
  PhoneCall,
  Receipt,
  Services,
  Search,
  Exit,
  CheckCircle,
  CheckFill,
  Heart,
  Market,
  DigitalAccount,
  NotificationCenter,
  Wallet,
  Dollar,
}
