import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import HireService from 'src/services/HireService'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'

import { LoadingActions } from '../loading/actions'
import { QuoteActions } from '../quote/actions'
import { errorHandlingSaga, redirectThroughSaga } from '../utils/providerSaga'
import { HireActions } from './actions'
import { HireTypes } from './types'

function* createHire({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { inspector } = payload

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    const { id } = yield* select(QuoteActions.getCurrentQuote)
    const quote = String(id)

    const data = { inspector, quote }

    const hireQuoteResponse = yield* call(HireService.createHire, userInfoBridge.account, data)

    yield* put(HireActions.setHiredQuote(hireQuoteResponse.object))

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
      route: TypesRoutes.SUMMARY,
    }

    yield* errorHandlingSaga(error as Error, 'HireActions.createHire', errorDetails)
  }
}

function* getHire({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())
    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    const { proposalNumber } = yield* select(QuoteActions.getCurrentQuote)

    if (proposalNumber) {
      const hireQuoteResponse = yield* call(
        HireService.getHire,
        userInfoBridge.account,
        proposalNumber,
      )
      yield* put(HireActions.setHiredQuote(hireQuoteResponse.object))
    }

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Essa tela está temporariamente indisponível, por isso não foi possível continuar. Tente novamente mais tarde.',
      disabledButton: true,
    }

    yield* errorHandlingSaga(error as Error, 'HireActions.getHire', errorDetails)
  }
}

export default function* watchHires() {
  yield* takeLatest(HireTypes.CREATE_HIRE, createHire)
  yield* takeLatest(HireTypes.GET_HIRED_QUOTE_REQUEST, getHire)
}
