import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'

import { H1, H3, PMedium, PSmall } from 'src/styles/commons'
import { Button } from 'src/components/Button/Button'
import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { useTheme } from 'styled-components'
import { Icons } from 'src/utils/icons'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentCardActions } from 'src/store/ducks/payment/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'

import {
  InterUIAlert,
  InterUIContainer,
  InterUIListDescription,
  InterUIRadio,
} from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { PriceTag } from './Payment.styles'

export const Payment = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const currentQuotes = useSelector(QuoteActions.getCurrentQuote)

  const [checked, setChecked] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  useBasePage({
    hideFaqBtn: true,
    showHomeBtn: false,
    navbarTitle: PageTitles.PAYMENT,
    backNavigationHandler: history.goBack,
  })

  const iconWallet = <Icons.Wallet width={24} height={24} color={theme.colors.primary.A500} />
  const iconArrowRight = (
    <Icons.ChevronRight width={24} height={24} color={theme.colors.primary.A500} />
  )

  const handleSelectedPayment = () => {
    if (ref.current) {
      setChecked(true)
      ref.current.checked = true
    }
  }

  const handleSetPaymentMethods = () => {
    dispatch(
      PaymentCardActions.setPaymentCard({
        border: theme.colors.primary.A400,
        icon: iconWallet,
        title: 'Débito Inter',
        arrow: iconArrowRight,
      }),
    )
    history.goBack()
  }

  const stickyFooter = (
    <InterUIContainer margin="0px">
      <Button
        data-testid="test-buttonPrimary"
        variant="primary"
        disabled={!checked}
        onClick={handleSetPaymentMethods}
      >
        <PMedium marginBottom="0">Definir forma de pagamento</PMedium>
      </Button>
    </InterUIContainer>
  )
  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <PriceTag margin="24px 0px 48px">
        <PMedium scale={400}>Valor Total</PMedium>
        <H1>{`${formatToBRL(currentQuotes.paymentMonthly ?? 0)}/mês`}</H1>
      </PriceTag>
      <H3 margin="0px">Como você quer pagar?</H3>
      <InterUIListDescription
        data-testid="test-selectedPayment"
        margin="24px 0px 0px"
        withBorder
        borderColor={checked ? theme.colors.primary.A500 : theme.colors.neutral.grayscale.A200}
        iconLeft={iconWallet}
        alignAllItems="center"
        onClick={handleSelectedPayment}
      >
        <InterUIRadio
          ref={ref}
          radioPosition="right"
          name="reason"
          padding="0px"
          border="none"
          defaultChecked={checked}
        >
          <PMedium marginBottom="0" bold>
            Débito Inter
          </PMedium>
        </InterUIRadio>
      </InterUIListDescription>
      <InterUIAlert margin="24px 0 0">
        <PSmall bold scale={500} marginBottom="4px">
          Pagamento parcelado
        </PSmall>
        <PSmall scale={500} marginBottom="0">
          O valor do seu Seguro será parcelado em 12 vezes no débito.
        </PSmall>
      </InterUIAlert>
    </InterUIContainer>
  )
}
