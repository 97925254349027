import { IDetailsCoverages } from 'src/interfaces/Quotation'

export enum CoverageEnum {
  BASICA = 'basica',
  ROUBO_BENS = 'roubo-bens',
  DANOS_ELETRICOS = 'danos-eletricos',
  ALUGUEL_INCENDIO = 'aluguel-incendio',
  DANOS_TERCEIROS = 'danos-terceiros',
  QUEBRA_VIDROS = 'quebra-vidros',
  VENDAVAL = 'vendaval-furacao',
  ASSISTENCIA_24H = 'assistencia-24h',
}

export const chooseCoverageUrl: { [status: string]: string } = {
  CBE10: CoverageEnum.BASICA,
  CMC01: CoverageEnum.DANOS_ELETRICOS,
  CMC02: CoverageEnum.ALUGUEL_INCENDIO,
  CMC03: CoverageEnum.QUEBRA_VIDROS,
  CMC04: CoverageEnum.DANOS_TERCEIROS,
  CMC05: CoverageEnum.ROUBO_BENS,
  CMC06: CoverageEnum.VENDAVAL,
  LAA01: CoverageEnum.ASSISTENCIA_24H,
}

export const mockDetailsCoverages: IDetailsCoverages[] = [
  {
    id: CoverageEnum.BASICA,
    title: 'Incêndio e outros',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        { description: 'Incêndios iniciados dentro ou fora do imóvel da empresa' },
        { description: 'Danos causados por queda de raio no terreno ou prédio da empresa' },
        { description: 'Explosões de aparelhos, substâncias e produtos' },
        { description: 'Implosões acidentais de tanques e recipientes' },
        { description: 'Fumaça causada por incêndios iniciados dentro ou fora da empresa' },
        { description: 'Queda de aeronaves' },
        { description: 'Remoção de entulhos causados pelos eventos previstos na cobertura' },
      ],
    },
  },
  {
    id: CoverageEnum.ROUBO_BENS,
    title: 'Roubo de bens',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        { description: 'Roubo em caso de violência ou ameaça' },
        { description: 'Furto com vestígios deixados pelo arrombamento de portas ou janelas' },
        { description: 'Danos causados ao imóvel pela tentativa de roubo ou furto' },
      ],
    },
  },
  {
    id: CoverageEnum.DANOS_ELETRICOS,
    title: 'Danos elétricos',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        { description: 'Curto-circuitos' },
        { description: 'Calor gerado acidentalmente por eletricidade' },
        { description: 'Descargas elétricas' },
        { description: 'Queda de raios' },
        { description: 'Variação de tensão fora do padrão em equipamentos e fiação elétrica' },
      ],
    },
  },
  {
    id: CoverageEnum.ALUGUEL_INCENDIO,
    title: 'Pagamento do aluguel em caso de incêndio',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        {
          description:
            'Pagamento de até 6 meses de aluguel (incluindo taxas de condomínio e IPTU) caso haja incêndio no imóvel da empresa,  a impedindo de funcionar.',
          subDescription: 'A indenização é feita diretamente ao proprietário do imóvel.',
        },
      ],
    },
  },
  {
    id: CoverageEnum.DANOS_TERCEIROS,
    title: 'Danos a clientes e terceiros',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        {
          description:
            'Pagamento de indenizações a terceiros ou clientes, quando o segurado for responsável por danos materiais e danos corporais',
        },
      ],
    },
  },
  {
    id: CoverageEnum.QUEBRA_VIDROS,
    title: 'Quebra de vidros',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        {
          description:
            'Danos materiais diretamente causados a vidros, inclusive blindados, mármores, granitos e espelhos instalados em portas, fachadas, vitrines e balcões, desde que danificados por eventos acidentais de causa externa',
        },
        {
          description:
            'Reparos ou reposição dos encaixes de vidros ou espelhos atingidos pelo sinistro',
        },
        {
          description:
            'Instalação provisória de vidros ou vedação nas aberturas que contenham os vidros danificados, durante o tempo necessário ao seu reparo ou substituição',
        },
      ],
    },
  },
  {
    id: CoverageEnum.VENDAVAL,
    title: 'Vendaval, furacão, ciclone, tornado e granizo',
    coverages: {
      label: 'Garantia de indenização, até o valor escolhido, para:',
      list: [
        {
          description:
            'Danos materiais causados diretamente aos bens da empresa por furacões, ciclones, tornados ou granizo.',
        },
        {
          description: 'Impacto de veículos terrestres de propriedade de terceiros',
        },
        {
          description:
            'Danos ao prédio e/ou conteúdo do imóvel por entrada de água em decorrência de destelhamento provocado pelo vendaval ou granizo.',
        },
      ],
    },
  },
  {
    id: CoverageEnum.ASSISTENCIA_24H,
    title: 'Assistências 24h',
    coverages: {
      label: 'Sua empresa conta com serviços emergencias de:',
      list: [
        { description: 'Chaveiro ' },
        { description: 'Eletricista' },
        { description: 'Encanador' },
        { description: 'Conserto de ar-condicionado' },
        { description: 'Manutenção de Rede telefônica' },
      ],
    },
    coveragesInsurance: {
      label: 'Em caso de sinistro, está amparada com:',
      list: [
        { description: 'Segurança e vigilância ' },
        { description: 'Guarda de móveis' },
        { description: 'Cobertura provisória de telhados' },
        { description: 'Colocação de tapume' },
      ],
    },
  },
]
