import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { PathParamsTypes } from './types'

const getUtmCampaignPathParams = (state: RootState) => state.pathparams.utmCampaignParams

const setUtmCampaignPathParams = (data: string) =>
  action(PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS, data)

export const PathParamsActions = {
  getUtmCampaignPathParams,
  setUtmCampaignPathParams,
}
