import React, { useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AddressActions } from 'src/store/ducks/address/actions'
import { ISearchAddress } from 'src/store/ducks/address/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { PMedium } from 'src/styles/commons'
import { formatNumbersOnly } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIBottomSheet,
  InterUICheckBox,
  InterUIContainer,
  InterUIInputGroup,
  InterUILoading,
} from '@interco/inter-ui-react-lib'
import { formatCpfOrCnpj } from '@interco/lib-util'

import { ContentLoading, ErrorMessage, NumberContainer, NumberGroup } from './UpdateAddress.styles'

interface IAddressForm extends ISearchAddress {
  cnpj: string
  companyName: string
  complement?: string
  number?: string
  noNumber?: boolean
}

export function UpdateAddress() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const currentQuotes = useSelector(QuoteActions.getCurrentQuote)
  const searchedAddress = useSelector(AddressActions.getSearchedAddress)
  const isLoading = useSelector(LoadingActions.get)

  const iconChevronRight = (
    <Icons.ChevronRight height={24} width={24} color={theme.colors.base.white} />
  )

  const [uncoveredZipCodeBS, setUncoveredZipCodeBS] = useState(false)
  const [noReceiveAddress, setNoReceiveAddress] = useState(false)
  const [renderAddress, setRenderAddress] = useState(false)
  const [blockFields, setBlockFields] = useState(false)
  const [valueZipCode, setValueZipCode] = useState('')

  useBasePage({
    navbarTitle: PageTitles.ADDRESS,
    backNavigationHandler: history.goBack,
  })

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<IAddressForm>({ mode: 'onChange' })

  const cnpj = watch('cnpj')
  const companyName = watch('companyName')
  const address = watch('address')
  const number = watch('number')
  const noNumber = watch('noNumber')
  const neighborhood = watch('neighborhood')
  const cnpjWithMask = formatCpfOrCnpj({ value: currentQuotes.cnpj })

  useEffect(() => {
    if (currentQuotes) {
      reset({ cnpj: cnpjWithMask, companyName: currentQuotes.companyName })
    }
  }, [])

  useEffect(() => {
    if (searchedAddress.zipCode && valueZipCode) {
      const fullAddress = `${searchedAddress.typeAddress} ${searchedAddress.address}`

      reset({
        ...searchedAddress,
        address: searchedAddress.address !== '' ? fullAddress : '',
        zipCode: valueZipCode,
        cnpj: cnpjWithMask,
        companyName: currentQuotes.companyName,
      })

      setBlockFields(true)
      setRenderAddress(true)
    } else if (searchedAddress && valueZipCode) {
      setUncoveredZipCodeBS(true)
    }
  }, [searchedAddress])

  const onChangeZipCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 9) {
      event.target.blur()
      getSearchAddress(event.target.value)
    } else {
      setRenderAddress(false)
      setValueZipCode('')
    }
  }

  const syncActionWithSaga = (data: ISearchAddress) => {
    if (data.address === '') {
      setNoReceiveAddress(true)
    }
  }

  const getSearchAddress = (value: string) => {
    setNoReceiveAddress(false)
    setValueZipCode(value)

    const payload = {
      zipCode: formatNumbersOnly(value),
      syncActionWithSaga,
    }

    dispatch(AddressActions.getAddressByCepRequest(payload))
  }

  const onSubmit = (data: IAddressForm) => {
    const addressData = {
      cnpj: formatNumbersOnly(currentQuotes.cnpj),
      companyName: currentQuotes.companyName,
      zipCode: formatNumbersOnly(data.zipCode),
      streetName: data.address,
      streetNumber: noNumber ? '' : data.number,
      streetExtraInformation: data.complement,
      districtName: data.neighborhood,
      city: data.city,
      state: data.district,
    }

    dispatch(
      AddressActions.updateAddressRequest({
        history,
        pathname: TypesRoutes.ADDRESS,
        address: addressData,
      }),
    )

    setValueZipCode('')
  }

  const handleUncoveredZipCode = () => {
    setUncoveredZipCodeBS(false)
    reset({ zipCode: '', cnpj, companyName })
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button
        data-testid="submit-button"
        type="submit"
        variant="primary"
        alignContent="space-between"
        disabled={
          valueZipCode?.length < 9 ||
          cnpj?.length < 18 ||
          !companyName ||
          !address ||
          !neighborhood ||
          (!noNumber && !number) ||
          isLoading
        }
      >
        <PMedium marginBottom="0">Salvar endereço</PMedium> {iconChevronRight}
      </Button>
    </InterUIContainer>
  )

  return (
    <>
      <form data-testid="submit-form" onSubmit={handleSubmit(onSubmit)}>
        <InterUIContainer margin="0" stickyFooter={stickyFooter}>
          <InterUIContainer margin="24px">
            <PMedium marginBottom="24px">
              Informe para qual CNPJ e endereço você gostaria de simular o Seguro Empresa Protegida.
            </PMedium>

            <Controller
              name="cnpj"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <InputMask
                  mask="99.999.999/9999-99"
                  maskChar=""
                  value={value}
                  onChange={onChange}
                  autoComplete="off"
                >
                  {() => (
                    <InterUIInputGroup invalid={!!errors.cnpj}>
                      <label htmlFor="cnpj">CNPJ</label>
                      <input
                        {...register('cnpj', {
                          required: true,
                          pattern: /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/g,
                        })}
                        type="text"
                        id="cnpj"
                        maxLength={18}
                        placeholder="00.000.000/0000-00"
                      />
                      {errors.cnpj?.type === 'required' && (
                        <ErrorMessage>Digite o número do CNPJ</ErrorMessage>
                      )}
                      {errors.cnpj?.type === 'pattern' && (
                        <ErrorMessage>Digite um número de CNPJ válido</ErrorMessage>
                      )}
                    </InterUIInputGroup>
                  )}
                </InputMask>
              )}
            />

            <InterUIInputGroup margin="16px 0 0" invalid={!!errors.companyName}>
              <label htmlFor="companyName">Razão Social</label>
              <input
                {...register('companyName', { required: true })}
                type="text"
                id="companyName"
                data-testid="companyName"
                autoComplete="off"
              />
              {errors.companyName && <ErrorMessage>Digite a Razão Social</ErrorMessage>}
            </InterUIInputGroup>

            <Controller
              name="zipCode"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <InputMask
                  mask="99999-999"
                  maskChar=""
                  value={value}
                  onChange={onChange}
                  autoComplete="off"
                >
                  {() => (
                    <InterUIInputGroup margin="16px 0 0" invalid={!!errors.zipCode}>
                      <label htmlFor="zipCode">CEP</label>
                      <input
                        {...register('zipCode', {
                          required: true,
                          pattern: /\d{5}-\d{3}/g,
                        })}
                        type="text"
                        inputMode="numeric"
                        data-testid="zipCode"
                        id="zipCode"
                        maxLength={10}
                        placeholder="00000-000"
                        autoComplete="off"
                        disabled={isLoading}
                        onChange={onChangeZipCode}
                      />
                      {errors.zipCode?.type === 'required' && (
                        <ErrorMessage>Digite o número do CEP</ErrorMessage>
                      )}
                      {errors.zipCode?.type === 'pattern' && (
                        <ErrorMessage>Digite um número de CEP válido</ErrorMessage>
                      )}
                    </InterUIInputGroup>
                  )}
                </InputMask>
              )}
            />

            {isLoading && !renderAddress && (
              <ContentLoading>
                <InterUILoading size="md" />
              </ContentLoading>
            )}

            {renderAddress && (
              <>
                <InterUIInputGroup
                  margin="16px 0 0"
                  invalid={!!errors.address}
                  readOnly={!noReceiveAddress && blockFields}
                >
                  <label htmlFor="address">Logradouro</label>
                  <input
                    {...register('address', { required: true })}
                    type="text"
                    id="address"
                    data-testid="address"
                    autoComplete="off"
                    readOnly={!noReceiveAddress && blockFields}
                  />
                  {errors.address && <ErrorMessage>Digite o endereço</ErrorMessage>}
                </InterUIInputGroup>

                <NumberContainer>
                  <NumberGroup>
                    <InterUIInputGroup disabled={noNumber} invalid={!!errors.number && !noNumber}>
                      <label htmlFor="number">Número</label>
                      <input
                        {...register('number', { required: !noNumber })}
                        type="number"
                        id="number"
                        data-testid="number"
                        autoComplete="off"
                        disabled={noNumber}
                      />
                    </InterUIInputGroup>

                    <InterUICheckBox
                      {...register('noNumber')}
                      id="noNumber"
                      data-testid="noNumber"
                      variant="simple"
                      margin="32px 0 0"
                      onChange={() => {
                        setValue('noNumber', !noNumber)
                        setValue('number', undefined)
                      }}
                    >
                      <PMedium marginBottom="0">Sem número</PMedium>
                    </InterUICheckBox>
                  </NumberGroup>
                  {errors.number && !noNumber && <ErrorMessage>Digite apenas números</ErrorMessage>}
                </NumberContainer>

                <InterUIInputGroup margin="16px 0 0">
                  <label htmlFor="complement">Complemento (Opcional) </label>
                  <input
                    {...register('complement')}
                    type="text"
                    id="complement"
                    data-testid="complement"
                    autoComplete="off"
                  />
                </InterUIInputGroup>

                <InterUIInputGroup
                  margin="16px 0 0"
                  invalid={!!errors.neighborhood}
                  readOnly={!noReceiveAddress && blockFields}
                >
                  <label htmlFor="neighborhood">Bairro</label>
                  <input
                    {...register('neighborhood', { required: true })}
                    type="text"
                    id="neighborhood"
                    data-testid="neighborhood"
                    autoComplete="off"
                    readOnly={!noReceiveAddress && blockFields}
                  />
                  {errors.neighborhood && <ErrorMessage>Digite o bairro</ErrorMessage>}
                </InterUIInputGroup>

                <InterUIInputGroup margin="16px 0 0" readOnly={blockFields}>
                  <label htmlFor="city">Cidade</label>
                  <input
                    {...register('city')}
                    type="text"
                    id="city"
                    data-testid="city"
                    autoComplete="off"
                    readOnly={blockFields}
                  />
                </InterUIInputGroup>

                <InterUIInputGroup margin="16px 0 0" readOnly={blockFields}>
                  <label htmlFor="district">Estado</label>
                  <input
                    {...register('district')}
                    type="text"
                    id="district"
                    data-testid="district"
                    autoComplete="off"
                    readOnly={blockFields}
                  />
                </InterUIInputGroup>
              </>
            )}
          </InterUIContainer>
        </InterUIContainer>
      </form>
      <InterUIBottomSheet
        toggle={uncoveredZipCodeBS}
        onHide={() => handleUncoveredZipCode()}
        title="CEP sem cobertura"
      >
        <PMedium scale={400}>
          No momento, infelizmente não temos nenhuma opção de seguro para o endereço informado
        </PMedium>

        <Button margin="20px 0" onClick={() => handleUncoveredZipCode()}>
          Informar outro CEP
        </Button>
      </InterUIBottomSheet>
    </>
  )
}
