import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { IPaymentCard, PaymentCardTypes } from './types'

const getPaymentCard = (state: RootState) => state.payment.selectedPaymentCard

const setPaymentCard = (data: IPaymentCard) => action(PaymentCardTypes.setPaymentCard, data)

export const PaymentCardActions = {
  getPaymentCard,
  setPaymentCard,
}
