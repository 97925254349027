import { CommonQuestions, CoverageDetails, Document } from 'src/components'
import { AfterSalesStatus, UnderReview } from 'src/pages/afterSales'
import { Start } from 'src/pages/start'
import { Home } from 'src/pages/subscription/home'
import {
  ChangeInspectionContact,
  ProfessionalActivities,
  Quotation,
  Receipt,
  SelectAddress,
  UpdateAddress,
} from 'src/pages/subscription/simulator'
import { Summary } from 'src/pages/subscription/simulator/Summary/Summary'
import { Payment } from 'src/pages/subscription/simulator/Summary/Payment/Payment'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  // START
  { route: Routes.START, component: Start },

  // COMMON QUESTIONS
  { route: Routes.COMMON_QUESTIONS, component: CommonQuestions },

  // SIMULATOR
  { route: Routes.HOME, component: Home },
  { route: Routes.CHANGE_INSPECTION_CONTACT, component: ChangeInspectionContact },
  { route: Routes.PROFESSIONAL_ACTIVITIES, component: ProfessionalActivities },
  { route: Routes.QUOTATION, component: Quotation },
  { route: Routes.RECEIPT, component: Receipt },
  { route: Routes.ADDRESS, component: SelectAddress },
  { route: Routes.UPDATE_ADDRESS, component: UpdateAddress },
  { route: Routes.SUMMARY, component: Summary },
  { route: Routes.PAYMENT, component: Payment },

  // AFTER SALES
  { route: Routes.AFTER_SALES_STATUS, component: AfterSalesStatus },
  { route: Routes.UNDER_REVIEW, component: UnderReview },

  // DOCUMENTS
  { route: Routes.DOCUMENTS, component: Document },
  { route: `${Routes.COVERAGE_DETAILS}:id`, component: CoverageDetails },
]

export * from './types'
