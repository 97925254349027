import { PSmall } from 'src/styles/commons'

import { InterUIList } from '@interco/inter-ui-react-lib'

export interface ISummaryItem {
  id: number
  text: string
  value: string
}
export interface ISummaryList {
  items: ISummaryItem[]
}

export const SummaryList = ({ items }: ISummaryList) => {
  const summaryList = items?.map((item) => (
    <InterUIList
      key={item.id}
      data-testid="test__summary-list"
      variant="horizontal"
      margin="0 0 16px"
      text={
        <PSmall scale={400} marginBottom="0px">
          {item.text}
        </PSmall>
      }
      value={
        <PSmall bold marginBottom="0px">
          {item.value}
        </PSmall>
      }
    />
  ))

  return <>{summaryList}</>
}
