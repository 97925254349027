import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import pdf from 'src/assets/pdf/manual.pdf'
import * as PDFJS from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { DocumentActions } from 'src/store/ducks/document/action'
import { HireActions } from 'src/store/ducks/hire/actions'

import { InterUIContainer, InterUILoading } from '@interco/inter-ui-react-lib'
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'

import { Button } from '..'

import '@react-pdf-viewer/core/lib/styles/index.css'
import { LoadContainer, PdfContainer } from './Document.styles'
import { base64toBlob } from './converter'
import { ErrorPage } from '../Error/ErrorPage/ErrorPage'

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker

export function Document() {
  const history = useHistory()

  const getRedirectDocument = useSelector(DocumentActions.getRedirectToDocument)
  const hiredQuote = useSelector(HireActions.getHiredQuote)

  const [enabledButton, setEnabledButton] = useState(false)

  const policyBlobURL = hiredQuote.proposalPdf ? base64toBlob(hiredQuote.proposalPdf) : undefined

  useBasePage({
    navbarTitle: getRedirectDocument?.type === 'MANUAL' ? PageTitles.MANUAL : PageTitles.POLICY,
    backNavigationHandler: history.goBack,
  })

  const stickyFooter = (
    <>
      {enabledButton && (
        <InterUIContainer margin="24px">
          <Button
            variant="primary"
            margin="0 0 16px"
            onClick={() => {
              history.goBack()
            }}
          >
            Voltar
          </Button>
        </InterUIContainer>
      )}
    </>
  )

  const loading = (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )

  if (getRedirectDocument?.type === 'MANUAL') {
    return (
      <InterUIContainer stickyFooter={stickyFooter} margin="0px">
        <PdfContainer>
          <Viewer
            fileUrl={pdf}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      </InterUIContainer>
    )
  }

  if (getRedirectDocument?.type === 'POLICY' && policyBlobURL) {
    return (
      <InterUIContainer stickyFooter={stickyFooter} margin="0px">
        <PdfContainer>
          <Viewer
            fileUrl={policyBlobURL}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      </InterUIContainer>
    )
  }

  return (
    <ErrorPage
      title="Houve um erro na exibição da sua apólice"
      subTitle="Tente novamente mais tarde."
      comeBackLater={() => history.goBack()}
      done={() => history.goBack()}
      goHome={() => history.goBack()}
    />
  )
}
