/**
 * Define o scroll no topo da página.
 */
export function scrollToTop() {
  window.scrollTo(0, 0)
}

/**
 * Retira caracteres especiais (incluindo espaço em branco) de uma determinada string
 * @param str string contendo caracteres especiais
 * @returns string sem caracteres especiais
 */
export const formatNumbersOnly = (str: string) => str.replace(/\D/g, '')

export function sortById() {
  return (elm1: { id: number }, elm2: { id: number }) => {
    if (elm1.id < elm2.id) {
      return -1
    }
    if (elm1.id > elm2.id) {
      return 1
    }
    return 0
  }
}

/**
 * Formata um numero para moeda Real sem o prefix "R$"
 * @param data numero a ser formatado
 * @returns uma string com o valor formatado em Reais
 */
export const numberFormat = (data: number | string) => {
  try {
    const formatter = data.toLocaleString('pt-br', { minimumFractionDigits: 2 })

    return formatter
  } catch {
    return data.toString()
  }
}

/**
 * Obtém o tamanho do input de acordo com o texto informado.
 * @param text texto informado no input.
 * @returns pixel do width do input
 */
export const getInputSizeByText = (text: string): string => {
  let size = 0
  const value = text.split('')

  value.forEach((char) => {
    if (char.match(/[.,]/g)) {
      size += 9
    } else if (char.match(/[1]/g)) {
      size += 11
    } else if (char.match(/[7]/g)) {
      size += 15
    } else if (char.match(/[235]/g)) {
      size += 16
    } else if (char.match(/[4689]/g)) {
      size += 17
    } else if (char.match(/[0]/g)) {
      size += 18
    }
  })

  return `${size}px`
}

/**
 * Recebe uma string e formata para um CEP
 * @param value string contendo caracteres
 * @returns string formatada
 */
export const maskZipCode = (value: string) => {
  if (!value) return ''
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{5})(\d)/, '$1-$2')
  return value
}

/**
 * Recebe uma string e formata a primeira letra para maiúscula
 * @param string string contendo caracteres
 * @returns string formatada
 */
export const formatFirstLetterCapital = (string: string) => {
  if (!string) return ''
  const formatString = string[0].toUpperCase() + string.substring(1).toLowerCase()
  return formatString
}

/**
 * Recebe uma data e formata para data e hora dd/mm/yyyy às hh:mm
 * @param value string contendo caracteres
 * @returns data e hora dd/mm/yyyy às hh:mm
 */
export const formatDataAndHours = (string: string) => {
  if (!string) return ''
  const date = new Date(string).toLocaleDateString('pt-br')
  const hour = new Date(string).getUTCHours().toString()
  const minutes = new Date(string).getUTCMinutes().toString()

  const formattedHour = `${`0${hour}`.slice(-2)}`
  const formattedMinutes = `${`0${minutes}`.slice(-2)}`

  return `${date} às ${formattedHour}:${formattedMinutes}`
}

/**
 * Recebe uma array de objetos e faz um deep clone
 * @param T[] array de objetos original
 * @returns array de objetos clonado (com novas referências)
 */
export function deepCloneArrayObjects<T>(arr: T[]) {
  const cloneArr = arr.map((item) => ({ ...item }))
  return cloneArr
}

export function convertStringToBrazilianDateString(dateStr: string) {
  const dateArr = dateStr.split('-')
  return dateArr.length === 3 ? `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}` : '-'
}
