import BaseService from 'src/config/bridge/BaseService'
import { ICoverage } from 'src/store/ducks/coverages/types'

import { MockService } from './mockService'

export default class CoveragesService {
  static getCoverages() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<ICoverage[]>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/coverages`,
      headers,
    })
  }
}
