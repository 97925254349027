export enum PaymentCardTypes {
  getPaymentCard = `@paymentCard/getPaymentCard`,
  setPaymentCard = `@paymentCard/setPaymentCard`,
}

export interface IPaymentCard {
  border: string
  icon: JSX.Element
  title: string
  arrow: JSX.Element
}

export interface PaymentCardState {
  readonly selectedPaymentCard: IPaymentCard
}
