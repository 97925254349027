import { Reducer } from 'redux'

import { IPaymentCard, PaymentCardState, PaymentCardTypes } from './types'

const INITIAL_STATE: PaymentCardState = {
  selectedPaymentCard: {} as IPaymentCard,
}

const reducer: Reducer<PaymentCardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentCardTypes.setPaymentCard:
      return { ...state, selectedPaymentCard: action.payload }

    default:
      return state
  }
}

export default reducer
