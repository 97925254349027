import { ICoverage } from '../coverages/types'
import { IDiscount } from '../discounts/types'
import { IProfessionalActivity } from '../professionalActivities/types'
import { SagaWithHistory } from '../utils/providerSaga'

export enum QuoteTypes {
  CREATE_QUOTE = `@quote/CREATE_QUOTE`,
  CREATE_SIMULATION = `@quote/CREATE_SIMULATION`,
  CALCULATE_SIMULATION = `@quote/CALCULATE_SIMULATION`,
  GET_QUOTES_REQUEST = `@quotes/GET_QUOTES_REQUEST`,
  GET_QUOTES = `@quotes/GET_QUOTES`,
  SET_QUOTES = `@quotes/SET_QUOTES`,
  GET_CURRENT_QUOTE = `@quote/GET_CURRENT_QUOTE`,
  SET_CURRENT_QUOTE = `@quote/SET_CURRENT_QUOTE`,
  GET_WAS_MODIFIED = `@quote/GET_WAS_MODIFIED`,
  SET_WAS_MODIFIED = `@quote/SET_WAS_MODIFIED`,
}

export interface IQuotesRequest {
  onTransmission: (status: string) => void
}

export interface ICreateSimulationRequest extends SagaWithHistory {
  professionalActivity: IProfessionalActivity
}

export interface ICalculateRequest extends SagaWithHistory {
  coverages: ICoverage[]
  discounts: IDiscount[]
  professionalActivity: IProfessionalActivity
}

export interface IQuote {
  id: number
  bankAccount: string
  cnpj: string
  companyName: string
  status: string
  libertyStreetType: string
  zipCode: string
  streetNumber: string
  streetName: string
  streetExtraInformation: string
  districtName: string
  city: string
  state: string
  netTotalPremium?: number
  needInspectionRisk: boolean
  paymentMonthly?: number
  proposalNumber?: string
  ProfessionalActivity: IProfessionalActivity
  Coverages: ICoverage[]
  Discounts: IDiscount[]
}

export interface IQuotesState {
  readonly quotes: IQuote[]
  readonly currentQuote: IQuote
  readonly wasModified: boolean
}
