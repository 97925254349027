/**
 * Rotas disponíveis na aplicação.
 */
export enum TypesRoutes {
  START = '/',
  HOME = '/home',
  COMMON_QUESTIONS = '/common-questions',

  // SIMULATION
  QUOTATION = '/quotation',

  // ADDRESS
  ADDRESS = '/select-address',
  UPDATE_ADDRESS = '/update-address',

  // AFTER_SALES
  AFTER_SALES_STATUS = '/after-sales-status',
  UNDER_REVIEW = '/under-review',

  PROFESSIONAL_ACTIVITIES = '/professional-activities',

  // SUMMARY
  SUMMARY = '/summary',
  PAYMENT = '/payment',
  CHANGE_INSPECTION_CONTACT = '/change-inspection-contact',

  // COMPONENTS
  DOCUMENTS = '/documents',
  RECEIPT = '/receipt',

  COVERAGE_DETAILS = '/coverage-details/',
}
