import styled from 'styled-components'

export const PrefixPricing = styled.span<{ error: boolean }>`
  padding: 16px 0px 4px 0px;
  font-family: 'Sora';
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: ${(props) =>
    props.error
      ? props.theme.colors.feedback.error.A500
      : props.theme.colors.neutral.grayscale.A400};
`

export const ContentPricing = styled.div<{ error: boolean }>`
  display: flex;
  gap: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A300};
  width: fit-content;
  margin-bottom: 8px;

  input {
    border: none;
    color: ${(props) =>
      props.error
        ? props.theme.colors.feedback.error.A500
        : props.theme.colors.neutral.grayscale.A500};
  }
`
