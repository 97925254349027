export enum DiscountsTypes {
  GET_DISCOUNTS = `@discounts/GET_DISCOUNTS`,
  SET_DISCOUNTS = `@discounts/SET_DISCOUNTS`,
  GET_DISCOUNTS_REQUEST = `@discounts/GET_DISCOUNTS_REQUEST`,
  DESELECT_ALL_DISCOUNTS = `@discounts/DESELECT_ALL_DISCOUNTS`,
}

export interface IDiscount {
  id: number
  name: string
  description: string
  isQuestion: boolean
  active: boolean
  libertyId?: string
  selected?: boolean
}

export interface DiscountsState {
  readonly discounts: IDiscount[]
}
