export enum NavbarTypes {
  SET_TITLE = `@navbar/SET_TITLE`,
  TOGGLE_FAQ_BTN = `@navbar/TOGGLE_FAQ_BTN`,
  TOGGLE_HOME_BTN = `@navbar/TOGGLE_HOME_BTN`,
  SET_DIRECTION = `@navbar/SET_DIRECTION`,
  SET_BACK_NAVIGATION_HANDLER = `@navbar/SET_BACK_NAVIGATION_HANDLER`,
  TOGGLE_BACK_NAVIGATION_BTN = `@navbar/TOGGLE_BACK_NAVIGATION_BTN`,
}

export interface NavbarState {
  readonly title: string
  readonly toggleFaqBtn: boolean
  readonly toggleHomeBtn: boolean
  readonly direction: string
  readonly toggleBackNavigationBtn: boolean

  readonly backNavigationHandler: () => void
}
