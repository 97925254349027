import styled from 'styled-components'

/**
 * Container que exibe valor do preço.
 */
export const PriceTag = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.margin && `margin: ${props.margin}`};
  padding: 16px 0;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
`
