import { useState } from 'react'

import { AfterSalesEnum } from 'src/enums/AfterSalesEnum'
import { IHiredQuote } from 'src/store/ducks/hire/types'
import { PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CoveragesActions } from 'src/store/ducks/coverages/actions'
import { DiscountsActions } from 'src/store/ducks/discounts/actions'
import { convertStringToBrazilianDateString } from 'src/utils/commons'
import { ITagAfterSales } from 'src/pages/afterSales/AfterSalesStatus/AfterSalesStatus'

import { InterUIList, InterUISeparator, InterUITag } from '@interco/inter-ui-react-lib'
import { formatCpfOrCnpj, formatToBRL } from '@interco/lib-util'

import { AfterSalesBS } from '../AfterSalesBS/AfterSalesBS'
import { Button } from '../Button/Button'
import { SummaryList } from '../SummaryList/SummaryList'
import { ContentTag } from './SubscriptionDetails.style'

interface SubscriptionDetailsProps {
  status: string
  cnpj: string
  afterSalesInfo: IHiredQuote
  handleTag: ({ content_action }: ITagAfterSales) => void
}

export interface ISubscriptionDetails {
  id: number
  text: string
  value: string
}

export const SubscriptionDetails = ({
  status,
  afterSalesInfo,
  cnpj,
  handleTag,
}: SubscriptionDetailsProps) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const iconSize = 16
  const colorGrayscale = theme.colors.neutral.grayscale.A500

  const iconInfo = (
    <Icons.Info
      width={iconSize}
      height={iconSize}
      color={colorGrayscale}
      data-testid="test__btn-info"
      onClick={() => setAfterSalesBS(true)}
    />
  )

  const [afterSalesBS, setAfterSalesBS] = useState(false)

  const defaultDetails: ISubscriptionDetails[] = [
    { id: 0, text: 'Empresa', value: afterSalesInfo.companyName },
    { id: 1, text: 'CNPJ', value: formatCpfOrCnpj({ value: cnpj }) },
    {
      id: 2,
      text: 'Início da vigência',
      value:
        afterSalesInfo.initialDate &&
        convertStringToBrazilianDateString(afterSalesInfo.initialDate),
    },
    {
      id: 3,
      text: 'Fim da vigência',
      value: afterSalesInfo.endDate && convertStringToBrazilianDateString(afterSalesInfo.endDate),
    },
    { id: 4, text: 'Mensalidade', value: formatToBRL(Number(afterSalesInfo.monthlyValue)) },
    {
      id: 5,
      text: 'Data da cobrança',
      value: `Todo dia ${afterSalesInfo.paymentDate}`,
    },
    { id: 6, text: 'Forma de pagamento', value: 'Débito em conta' },
    { id: 7, text: 'Protocolo', value: afterSalesInfo.protocol },
  ]

  const finishedDetails: ISubscriptionDetails[] = [
    { id: 2, text: 'Empresa', value: afterSalesInfo.companyName },
    { id: 3, text: 'CNPJ', value: formatCpfOrCnpj({ value: cnpj }) },
    {
      id: 4,
      text: 'Início da vigência',
      value:
        afterSalesInfo.initialDate &&
        convertStringToBrazilianDateString(afterSalesInfo.initialDate),
    },
    {
      id: 5,
      text: 'Fim da vigência',
      value: afterSalesInfo.endDate && convertStringToBrazilianDateString(afterSalesInfo.endDate),
    },
    { id: 6, text: 'Mensalidade', value: formatToBRL(Number(afterSalesInfo.monthlyValue)) },
    {
      id: 7,
      text: 'Data da cobrança',
      value: `Todo dia ${afterSalesInfo.paymentDate}`,
    },
    { id: 8, text: 'Forma de pagamento', value: 'Débito em conta' },
    { id: 9, text: 'Protocolo', value: afterSalesInfo.protocol },
  ]

  const canceledDetails: ISubscriptionDetails[] = [
    { id: 0, text: 'Protocolo de cancelamento', value: afterSalesInfo.protocolCancel ?? '' },
    {
      id: 1,
      text: 'Data de cancelamento',
      value: afterSalesInfo.cancelDate
        ? convertStringToBrazilianDateString(afterSalesInfo.cancelDate)
        : '',
    },
    { id: 2, text: 'Empresa', value: afterSalesInfo.companyName },
    { id: 3, text: 'CNPJ', value: formatCpfOrCnpj({ value: cnpj }) },
    {
      id: 4,
      text: 'Início da vigência',
      value:
        afterSalesInfo.initialDate &&
        convertStringToBrazilianDateString(afterSalesInfo.initialDate),
    },
    {
      id: 5,
      text: 'Fim da vigência',
      value: afterSalesInfo.endDate && convertStringToBrazilianDateString(afterSalesInfo.endDate),
    },
    { id: 6, text: 'Mensalidade', value: formatToBRL(Number(afterSalesInfo.monthlyValue)) },
    {
      id: 7,
      text: 'Data da cobrança',
      value: `Todo dia ${afterSalesInfo.paymentDate}`,
    },
    { id: 8, text: 'Forma de pagamento', value: 'Débito em conta' },
    { id: 9, text: 'Protocolo', value: afterSalesInfo.protocol },
  ]

  const notApprovedDetails: ISubscriptionDetails[] = [
    { id: 1, text: 'Empresa', value: afterSalesInfo.companyName },
    { id: 2, text: 'CNPJ', value: formatCpfOrCnpj({ value: cnpj }) },
    { id: 3, text: 'Mensalidade', value: formatToBRL(Number(afterSalesInfo.monthlyValue)) },
  ]

  const chooseDetails: { [status: string]: ISubscriptionDetails[] } = {
    [AfterSalesEnum.IN_ANALYSIS]: defaultDetails,
    [AfterSalesEnum.ACTIVE]: defaultDetails,
    [AfterSalesEnum.NOT_APPROVED]: notApprovedDetails,
    [AfterSalesEnum.CANCELED]: canceledDetails,
    [AfterSalesEnum.FINISHED]: finishedDetails,
  }

  const goToHome = () => {
    dispatch(CoveragesActions.getCoveragesRequest())
    dispatch(DiscountsActions.getDiscountsRequest())

    history.push(TypesRoutes.HOME)
  }

  const handleClick = (buttonName: string) => {
    handleTag({ content_id: buttonName, content_action: 'clique' })
    goToHome()
  }

  const headerInAnalysis = (
    <InterUIList
      margin="0 0 16px"
      value={
        <ContentTag>
          <InterUITag
            backgroundColor={theme.colors.feedback.warning.A100}
            color={theme.colors.neutral.grayscale.A500}
          >
            Em análise
          </InterUITag>
          {iconInfo}
        </ContentTag>
      }
      text={
        <PSmall scale={400} marginBottom="0px">
          Status do seguro
        </PSmall>
      }
    />
  )

  const headerActive = (
    <InterUIList
      margin="0 0 16px"
      value={<InterUITag variant="success">Ativo</InterUITag>}
      text={
        <PSmall scale={400} marginBottom="0px">
          Status do seguro
        </PSmall>
      }
    />
  )

  const headerNotApproved = (
    <>
      <InterUIList
        margin="0 0 16px"
        value={
          <ContentTag>
            <InterUITag
              variant="error"
              backgroundColor={theme.colors.neutral.grayscale.A100}
              color={theme.colors.neutral.grayscale.A500}
            >
              Não aprovado
            </InterUITag>
            {iconInfo}
          </ContentTag>
        }
        text={
          <PSmall scale={400} marginBottom="0px">
            Status do seguro
          </PSmall>
        }
      />

      <Button variant="primary" small onClick={() => handleClick('Realizar nova cotação')}>
        Realizar nova cotação
      </Button>

      <InterUISeparator height="1px" margin="16px 0" />
    </>
  )

  const headerCanceled = (
    <>
      <InterUIList
        margin="0 0 16px"
        value={
          <ContentTag>
            <InterUITag
              backgroundColor={theme.colors.neutral.grayscale.A100}
              color={theme.colors.neutral.grayscale.A500}
            >
              Cancelado
            </InterUITag>
            {iconInfo}
          </ContentTag>
        }
        text={
          <PSmall scale={400} marginBottom="0px">
            Status do seguro
          </PSmall>
        }
      />

      <Button variant="primary" small onClick={() => handleClick('Contratar novamente')}>
        Contratar novamente
      </Button>

      <InterUISeparator height="1px" margin="16px 0" />
    </>
  )

  const headerFinished = (
    <>
      <InterUIList
        margin="0 0 16px"
        value={
          <ContentTag>
            <InterUITag
              backgroundColor={theme.colors.neutral.grayscale.A100}
              color={theme.colors.neutral.grayscale.A500}
            >
              Finalizado
            </InterUITag>
            {iconInfo}
          </ContentTag>
        }
        text={
          <PSmall scale={400} marginBottom="0px">
            Status do seguro
          </PSmall>
        }
      />

      <Button variant="primary" small onClick={() => handleClick('Contratar novamente')}>
        Contratar novamente
      </Button>

      <InterUISeparator height="1px" margin="16px 0" />
    </>
  )

  const renderAfterSalesHeader: { [status: string]: JSX.Element } = {
    [AfterSalesEnum.IN_ANALYSIS]: headerInAnalysis,
    [AfterSalesEnum.ACTIVE]: headerActive,
    [AfterSalesEnum.NOT_APPROVED]: headerNotApproved,
    [AfterSalesEnum.CANCELED]: headerCanceled,
    [AfterSalesEnum.FINISHED]: headerFinished,
  }

  return (
    <>
      {renderAfterSalesHeader[status]}
      <SummaryList items={chooseDetails[status]} />
      {status !== 'ACTIVE' && (
        <AfterSalesBS
          status={status}
          toggleState={[afterSalesBS, setAfterSalesBS]}
          handleClick={goToHome}
          handleTag={handleTag}
        />
      )}
    </>
  )
}
