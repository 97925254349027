import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BannerImg from 'src/assets/img/banner.png'
import { Button } from 'src/components'
import { PriceBox } from 'src/components/PriceBox/PriceBox'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { H1, H3, PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TAGS } from 'src/enums/TaggingEnum'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIButton,
  InterUIContainer,
  InterUIListDescription,
} from '@interco/inter-ui-react-lib'

import { HomeIcons } from './Home.style'
import { QuoteStatusEnum } from '../../../../enums/QuoteStatusEnum'

export const Home = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const account_context = useSelector(NewRelicActions.getUserContext)

  const isLoading = useSelector(LoadingActions.get)

  const quotes = useSelector(QuoteActions.getQuotes)
  const hasQuotes = quotes && quotes.length > 0

  const currentQuote = useSelector(QuoteActions.getCurrentQuote)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const INITIAL_COVERAGE = 'R$ 50.000,00'
  const MONTHLY_PRICE = 'R$ 20,00'

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '1',
      account_context,
      content_id: 'Home',
      tela: 'Home',
      content_action: 'tela carregada',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }, [])

  useBasePage({
    navbarTitle: PageTitles.PROTECTED,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  interface IAdvantage {
    id: number
    icon: JSX.Element
    description: string
  }

  const advantageIconSize = 16
  const advantageIcons = {
    iconHeart: (
      <Icons.Heart
        height={advantageIconSize}
        width={advantageIconSize}
        color={theme.colors.primary.A500}
      />
    ),
    iconMarket: (
      <Icons.Market
        height={advantageIconSize}
        width={advantageIconSize}
        color={theme.colors.primary.A500}
      />
    ),
    iconDigitalAccount: (
      <Icons.DigitalAccount
        height={advantageIconSize}
        width={advantageIconSize}
        color={theme.colors.primary.A500}
      />
    ),
  }

  const iconChevronRightWhite = (
    <Icons.ChevronRight height={24} width={24} color={theme.colors.base.white} />
  )

  const iconChevronRightA500 = (
    <Icons.ChevronRight height={24} width={24} color={theme.colors.primary.A500} />
  )

  const advantages: IAdvantage[] = [
    {
      id: 0,
      icon: advantageIcons.iconHeart,
      description: 'Personalize as suas coberturas e contrate direto pelo Super App',
    },
    {
      id: 1,
      icon: advantageIcons.iconMarket,
      description:
        'Proteção em caso de incêndio, explosão, roubo, furto, danos elétricos, entre outros',
    },
    {
      id: 2,
      icon: advantageIcons.iconDigitalAccount,
      description:
        'Pagamento do aluguel em caso de incêndio, além de serviços de eletricista, encanador, chaveiro e muito mais!',
    },
  ]

  const renderAdvantages = (
    <>
      {advantages.map((item) => {
        const key = `advantage-${item.id}`
        const ico = <HomeIcons>{item.icon}</HomeIcons>

        return (
          <InterUIListDescription margin="24px 0" key={key} iconLeft={ico} alignAllItems="center">
            <PMedium marginBottom="0">{item.description}</PMedium>
          </InterUIListDescription>
        )
      })}
    </>
  )

  function handleHireButton(buttonName: string) {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '2',
      account_context,
      content_id: buttonName,
      tela: 'Home',
      content_action: 'clique',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })

    dispatch(
      QuoteActions.createQuote({
        history,
        pathname: TypesRoutes.ADDRESS,
      }),
    )
  }

  function handleContinueButton() {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '2',
      account_context,
      content_id: 'Continuar simulação',
      tela: 'Home',
      content_action: 'clique',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })

    currentQuote && currentQuote.status === QuoteStatusEnum.QUOTE_IN_PROGRESS
      ? history.push(TypesRoutes.QUOTATION)
      : history.push(TypesRoutes.ADDRESS)
  }

  return (
    <InterUIContainer margin="32px 0 24px 0">
      <img src={BannerImg} style={{ width: '100%' }} alt="Banner Seguro Empresa Protegida" />
      <InterUIContainer margin="0px 24px 0 24px">
        <H1 scale={600} margin="24px 0 8px" color={theme.colors.primary.A500}>
          Completo, rápido e 100% digital
        </H1>
        <PMedium scale={400} marginBottom="24px">
          O seguro com as coberturas feitas sob medida para o seu negócio. Aqui você tem garantias
          contra incêndio, roubo, danos elétricos, assistência 24h e muito mais!
        </PMedium>
        <PriceBox price={INITIAL_COVERAGE} monthlyPrice={MONTHLY_PRICE} />
        <H3 margin="32px 0 0" color={theme.colors.primary.A500}>
          Conheça as vantagens
        </H3>

        {renderAdvantages}

        {!hasQuotes && (
          <Button
            data-testid="hire-button"
            onClick={() => handleHireButton('Conhecer')}
            variant="primary"
            margin="32px 0 0"
            alignContent="space-between"
            disabled={isLoading}
          >
            <PMedium marginBottom="0">Conhecer</PMedium> {iconChevronRightWhite}
          </Button>
        )}

        {hasQuotes &&
          (currentQuote.status === QuoteStatusEnum.CREATED ||
            currentQuote.status === QuoteStatusEnum.QUOTE_IN_PROGRESS) && (
            <>
              <InterUIButton
                data-testid="continue-button"
                onClick={handleContinueButton}
                variant="primary"
                margin="32px 0 16px"
                alignContent="space-between"
                disabled={isLoading}
              >
                <PMedium marginBottom="0">Continuar simulação</PMedium> {iconChevronRightWhite}
              </InterUIButton>
              <Button
                data-testid="reset-button"
                onClick={() => handleHireButton('Fazer nova simulação')}
                variant="secondary"
                alignContent="space-between"
                disabled={isLoading}
              >
                <PMedium marginBottom="0" color="">
                  Fazer nova simulação
                </PMedium>{' '}
                {iconChevronRightA500}
              </Button>
            </>
          )}

        {hasQuotes && currentQuote.status === QuoteStatusEnum.FINISHED && (
          <Button
            data-testid="new-hire-button"
            onClick={() => handleHireButton('Fazer nova simulação')}
            variant="primary"
            margin="32px 0 0"
            alignContent="space-between"
            disabled={isLoading}
          >
            <PMedium marginBottom="0">Fazer nova simulação</PMedium> {iconChevronRightWhite}
          </Button>
        )}
      </InterUIContainer>
    </InterUIContainer>
  )
}
