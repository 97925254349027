import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadHome } from 'src/components/Skeleton/LoadHome/LoadHome'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { QuoteStatusEnum } from 'src/enums/QuoteStatusEnum'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathQueryParams } from 'src/routes/pathQueryParams'
import { CoveragesActions } from 'src/store/ducks/coverages/actions'
import { DiscountsActions } from 'src/store/ducks/discounts/actions'
import { HireActions } from 'src/store/ducks/hire/actions'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { ContainerFullPage } from 'src/styles/commons'

export const Start = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handler = PathQueryParams()

  BaseBridge.requestAnalytics('EP_TAG_START', {
    ref_figma: '1',
    tela: 'Entrou no Start page',
  })

  BaseBridge.requestAnalytics('EP_TAG_START_PLATFORM', {
    ref_figma: '1',
    tela: 'Entrou no Start page com Platform',
    platform: 'APP',
  })

  BaseBridge.requestAnalytics('EP_TAG_START_PRODUCT', {
    ref_figma: '1',
    tela: 'Entrou no Start page com Platform',
    product: 'xpto',
  })

  function goToAfterSales() {
    dispatch(CoveragesActions.getCoveragesRequest())
    dispatch(
      HireActions.getHireRequest({
        history,
        pathname: TypesRoutes.AFTER_SALES_STATUS,
      }),
    )
  }

  function goToHome() {
    dispatch(CoveragesActions.getCoveragesRequest())
    dispatch(DiscountsActions.getDiscountsRequest())

    history.push(TypesRoutes.HOME)
  }

  function checkCurrentQuote(currentQuoteStatus: string) {
    currentQuoteStatus === QuoteStatusEnum.FINISHED ? goToAfterSales() : goToHome()
  }

  useEffect(() => {
    handler()

    const payload = { onTransmission: checkCurrentQuote }
    dispatch(QuoteActions.getQuotesRequest(payload))
  }, [dispatch])

  return (
    <ContainerFullPage height="0px" margin="0" overflowHidden>
      <LoadHome />
    </ContainerFullPage>
  )
}
