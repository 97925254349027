import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { ContainerFullPage, H2, H3, PMedium, PSmall } from 'src/styles/commons'
import { Button } from 'src/components'
import { useTheme } from 'styled-components'
import { Icons } from 'src/utils/icons'
import { CoverageEnum, chooseCoverageUrl } from 'src/mocks/Quotation'
import Assistance24hImg from 'src/assets/img/assistencia24h.png'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentActions } from 'src/store/ducks/document/action'
import { DiscountsActions } from 'src/store/ducks/discounts/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { LoadQuotation } from 'src/components/Skeleton/LoadQuotation/LoadQuotation'
import { CoveragesActions } from 'src/store/ducks/coverages/actions'
import {
  ICoverage,
  LibertyIdEnum,
  MIN_ADDITIONAL_COVERAGE,
  MIN_BASIC_COVERAGE,
} from 'src/store/ducks/coverages/types'
import { QuoteActions } from 'src/store/ducks/quote/actions'
import { IDiscount } from 'src/store/ducks/discounts/types'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'

import {
  InterUIBox,
  InterUIContainer,
  InterUIList,
  InterUIListDescription,
  InterUIProgressBar,
  InterUISeparator,
  InterUISwitch,
  InterUITag,
} from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import {
  CoverageContainer,
  CoverageSwitchBox,
  EditPriceBox,
  DeductiblePrice,
  KnowMore,
  QuotationFooterContainer,
} from './Quotation.styles'
import { QuotationDiscountsBS } from './QuotationDiscountsBS/QuotationDiscountsBS'
import { AdditionalCoveragePriceBS } from './AdditionalCoveragePriceBS/AdditionalCoveragePriceBS'
import { BtnEditPrice } from './AdditionalCoveragePriceBS/AdditionalCoveragePriceBS.styles'
import { QuoteStatusEnum } from '../../../../enums/QuoteStatusEnum'
import { MinAdditionalCoveragesBS } from './MinAdditionalCoveragesBS/MinAdditionalCoveragesBS'
import { BasicCoverage } from './BasicCoverage/BasicCoverage'
import { CalculationFooter } from './CalculationFooter/CalculationFooter'

export function Quotation() {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const iconSize = 24

  const discountsList = useSelector(DiscountsActions.getDiscounts)
  const coveragesList = useSelector(CoveragesActions.getSimulationCoverages)
  const currentQuote = useSelector(QuoteActions.getCurrentQuote)
  const wasModified = useSelector(QuoteActions.getWasModified)

  const isLoading = useSelector(LoadingActions.get)

  const [showDiscountsBS, setShowDiscountsBS] = useState(false)
  const [showCoveragePriceBS, setShowCoveragePriceBS] = useState(false)
  const [showMinCoveragesBS, setShowMinCoveragesBS] = useState(false)
  const [coverageToEdit, setCoverageToEdit] = useState({} as ICoverage)
  const [coverages, setCoverages] = useState(coveragesList)
  const [discounts, setDiscounts] = useState(discountsList)

  const account_context = useSelector(NewRelicActions.getUserContext)

  // TODO calcular essa cobertura máxima (depende das coberturas CMC02 e CMC04)
  const MAX_BASIC_COVERAGE_VALUE = 10_000_000
  const FORM_PAYMENT = 'Débito em conta'

  const handleBackButton = () => {
    dispatch(QuoteActions.setWasModified(false))
    setTimeout(() => history.push(TypesRoutes.PROFESSIONAL_ACTIVITIES), 1)
  }

  useBasePage({
    navbarTitle: PageTitles.PROTECTED,
    backNavigationHandler: () => handleBackButton(),
  })

  useEffect(() => {
    setDiscounts(discountsList)
  }, [discountsList])

  useEffect(() => {
    setCoverages(coveragesList)
  }, [coveragesList])

  const hasAdditionalCoverage = () => {
    const selected = coverages.filter(
      (coverage) => skipBasicAnd24hCoverages(coverage) && coverage.selected,
    )

    return selected.length > 0
  }

  const coveragesToString = () => {
    const selectedCoverages = coverages.filter(
      (cov) => cov.selected === true && cov.libertyId !== LibertyIdEnum.ASSISTENCIA_24H_OFF,
    )
    let coveragesStr = ''

    selectedCoverages.forEach((cov) => {
      if (cov.libertyId === LibertyIdEnum.ASSISTENCIA_24H_ON) {
        coveragesStr += ` ${cov.coverageDescription}: inclusa,`
      } else if (cov.insuredAmount) {
        coveragesStr += ` ${cov.coverageDescription}: ${formatToBRL(cov.insuredAmount)},`
      }
    })

    return coveragesStr
  }

  const handleTagging = () => {
    BaseBridge.requestAnalytics(TAGS.EMPRESA_PROTEGIDA.name, {
      ...TAGS.EMPRESA_PROTEGIDA,
      ref_figma: '6',
      account_context,
      tela: 'Comprovante',
      content_action: 'clique',
      forma_pag: FORM_PAYMENT,
      price: `${formatToBRL(Number(currentQuote?.paymentMonthly))}/mês`,
      content_coverage: coveragesToString(),
    })
  }

  const handleContinue = () => {
    handleTagging()
    dispatch(QuoteActions.setWasModified(false))
    hasAdditionalCoverage() ? history.push(TypesRoutes.SUMMARY) : setShowMinCoveragesBS(true)
  }

  const basicCoverage = coverages.filter((cov) => cov.libertyId === LibertyIdEnum.BASICA)[0]
  const basicCoverageValue = basicCoverage?.insuredAmount ?? MIN_BASIC_COVERAGE

  const selectedDiscountIds = () => {
    const ids: number[] = []

    discounts.forEach((discount) => {
      if (discount.selected) ids.push(discount.id)
    })

    return ids
  }

  const isBasicCoverageValid = () =>
    basicCoverageValue >= MIN_BASIC_COVERAGE && basicCoverageValue <= MAX_BASIC_COVERAGE_VALUE

  const handleCalculate = () => {
    handleTagging()
    const selectedCoverages = coverages.filter((cov) => cov.selected === true)
    const selectedAdditionalCoverages = selectedCoverages.filter((cov) =>
      skipBasicAnd24hCoverages(cov),
    )
    const selectedDiscounts = discounts.filter((disc) => disc.selected === true)

    if (selectedAdditionalCoverages.length === 0) {
      setShowMinCoveragesBS(true)
      return
    }

    dispatch(
      QuoteActions.calculateSimulation({
        coverages: selectedCoverages,
        discounts: selectedDiscounts,
        professionalActivity: currentQuote.ProfessionalActivity,
      }),
    )

    dispatch(QuoteActions.setWasModified(false))
  }

  const handleClickManual = () => {
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENTS,
        type: 'MANUAL',
      }),
    )
  }

  const hasSelectedDiscounts = () => {
    const selected = discounts.filter((discount) => discount.selected === true)
    return selected.length > 0
  }
  const updateDiscounts = (newDiscounts: IDiscount[]) => {
    setDiscounts([...newDiscounts])
    dispatch(QuoteActions.setWasModified(true))
  }

  const updateCoverage = (libertyId: string, selected: boolean, value?: number) => {
    const updatedCoverages = coverages.map((cov) => {
      if (cov.libertyId === libertyId) {
        cov.insuredAmount = value ?? cov.insuredAmount
        cov.selected = selected
      }
      return cov
    })

    setCoverages([...updatedCoverages])
    dispatch(QuoteActions.setWasModified(true))
  }

  const toggle24Assistance = () => {
    if (isCoverage24Checked()) {
      updateCoverage(LibertyIdEnum.ASSISTENCIA_24H_OFF, true)
      updateCoverage(LibertyIdEnum.ASSISTENCIA_24H_ON, false)
    } else {
      updateCoverage(LibertyIdEnum.ASSISTENCIA_24H_OFF, false)
      updateCoverage(LibertyIdEnum.ASSISTENCIA_24H_ON, true)
    }
  }

  // Não mostrar cobertura básica (CBE10) nem 24h (LAA01 e LAA12)
  const skipBasicAnd24hCoverages = (coverage: ICoverage) =>
    coverage.libertyId !== LibertyIdEnum.BASICA &&
    coverage.libertyId !== LibertyIdEnum.ASSISTENCIA_24H_OFF &&
    coverage.libertyId !== LibertyIdEnum.ASSISTENCIA_24H_ON

  const iconChevronContinueButton = (
    <Icons.ChevronRight
      width={iconSize}
      height={iconSize}
      color={wasModified ? theme.colors.neutral.grayscale.A300 : theme.colors.primary.A100}
    />
  )

  const iconChevronRight = (
    <Icons.ChevronRight height={18} width={18} color={theme.colors.primary.A500} />
  )

  const iconEdit = <Icons.Edit height={18} width={18} color={theme.colors.primary.A500} />
  const iconEdit24 = <Icons.Edit height={24} width={24} color={theme.colors.primary.A500} />

  const iconArrow = (
    <Icons.ChevronRight width={iconSize} height={iconSize} color={theme.colors.primary.A500} />
  )

  const iconManual = (
    <Icons.BookOpen width={iconSize} height={iconSize} color={theme.colors.primary.A500} />
  )

  const basicCoverageTag = (
    <InterUITag
      variant="action-button"
      style={{ minWidth: 'max-content', padding: '8px' }}
      backgroundColor={theme.colors.primary.A500}
      color={theme.colors.neutral.grayscale.A100}
    >
      Já inclusa
    </InterUITag>
  )

  const isCoverage24Checked = () => {
    const covLAA12 = coverages.filter(
      (cov) => cov.libertyId === LibertyIdEnum.ASSISTENCIA_24H_ON,
    )[0]
    return covLAA12?.selected
  }

  const buttonCharacteristics = (
    <Button
      data-testid="button-characteristics"
      disabled={!isBasicCoverageValid()}
      onClick={() => setShowDiscountsBS(true)}
      style={{ backgroundColor: theme.colors.primary.A100 }}
      alignContent="space-between"
      small
    >
      <PMedium color={theme.colors.primary.A500} marginBottom="0">
        {hasSelectedDiscounts() ? 'Editar características' : 'Conferir características'}
      </PMedium>
      {hasSelectedDiscounts() ? iconEdit : iconChevronRight}
    </Button>
  )

  const assistance24h = (
    <InterUIListDescription
      iconLeft={<img width={40} src={Assistance24hImg} alt="Ícone da assistência 24h" />}
    >
      <PMedium marginBottom="4px" bold scale={700}>
        Assistência 24h
      </PMedium>
    </InterUIListDescription>
  )

  const renderAdditionalCoverageBox = (coverage: ICoverage) => (
    <CoverageContainer>
      <CoverageSwitchBox>
        <PMedium margin="0" bold>
          {coverage.coverageDescription}
        </PMedium>
        <InterUISwitch
          disabled={!isBasicCoverageValid()}
          onChange={() => updateCoverage(coverage.libertyId, !coverage.selected)}
          checked={coverage.selected}
          justify="flex-end"
        />
      </CoverageSwitchBox>
      <EditPriceBox>
        <div>
          <PMedium margin="0" scale={400}>
            Cobertura de até
          </PMedium>
          <PMedium bold>{formatToBRL(coverage.insuredAmount ?? MIN_ADDITIONAL_COVERAGE)}</PMedium>
        </div>
        <BtnEditPrice
          disabled={!isBasicCoverageValid()}
          onClick={() => {
            setCoverageToEdit({ ...coverage })
            setShowCoveragePriceBS(true)
          }}
        >
          {iconEdit24}
        </BtnEditPrice>
      </EditPriceBox>
      {coverage.Deductible && (
        <PSmall scale={400}>
          Franquia de <DeductiblePrice>{formatToBRL(coverage.Deductible.Value)}</DeductiblePrice>
        </PSmall>
      )}
    </CoverageContainer>
  )

  const renderCoverages = coverages.map((coverage: ICoverage, index: number) => {
    const showSeparator = index < coverages.length - 1
    return skipBasicAnd24hCoverages(coverage) ? (
      <React.Fragment key={coverage.libertyId}>
        {renderAdditionalCoverageBox(coverage)}
        <KnowMore to={TypesRoutes.COVERAGE_DETAILS + chooseCoverageUrl[coverage.libertyId]}>
          Saber mais
        </KnowMore>

        {showSeparator && <InterUISeparator height="1px" margin="16px 0 24px" />}
      </React.Fragment>
    ) : (
      ''
    )
  })

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <QuotationFooterContainer>
        <CalculationFooter
          dataChanged={wasModified}
          paymentMonthly={currentQuote?.paymentMonthly ?? 0}
          disabled={!isBasicCoverageValid()}
          handleCalculate={handleCalculate}
        />
        <Button
          style={{ minWidth: 'auto' }}
          alignContent="space-between"
          variant="primary"
          disabled={wasModified}
          data-testid="continueButton"
          onClick={() => handleContinue()}
        >
          <PMedium marginBottom="0">Continuar</PMedium>
          {iconChevronContinueButton}
        </Button>
      </QuotationFooterContainer>
    </InterUIContainer>
  )

  const quotationLoading =
    isLoading ||
    currentQuote?.status !== QuoteStatusEnum.QUOTE_IN_PROGRESS ||
    coverages.length === 0 ||
    discounts.length === 0

  return !quotationLoading ? (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar progress="75%" />

      <InterUIContainer margin="24px 24px 32px">
        <PSmall marginBottom="24px" scale={300} bold>
          Etapa 3/4
        </PSmall>
        <H2>Personalize as proteções para a sua empresa</H2>

        <H3 margin="32px 0 8px">{basicCoverage?.coverageDescription}</H3>

        <InterUIListDescription small iconRight={basicCoverageTag}>
          <PMedium scale={400}>{basicCoverage?.coverageDetail}</PMedium>
        </InterUIListDescription>

        <PMedium margin="20px 0 0" scale={400}>
          Selecione o valor da cobertura
        </PMedium>

        <BasicCoverage
          max={MAX_BASIC_COVERAGE_VALUE}
          price={basicCoverageValue}
          updateCoveragePrice={(newPrice) => {
            updateCoverage(LibertyIdEnum.BASICA, true, newPrice)
            setCoverageToEdit({} as ICoverage)
          }}
        />

        <KnowMore to={TypesRoutes.COVERAGE_DETAILS + CoverageEnum.BASICA}>Saber mais</KnowMore>
      </InterUIContainer>

      <InterUISeparator height="8px" margin="0" />

      <InterUIContainer margin="24px 24px 32px">
        <H3 margin="48px 0 8px">Coberturas adicionais</H3>

        <PMedium marginBottom="24px" scale={400}>
          Elas garantem a proteção da sua empresa aos demais imprevistos do dia a dia. Você deve
          selecionar pelo menos uma.
        </PMedium>

        {renderCoverages}
      </InterUIContainer>

      <InterUISeparator height="8px" margin="0" />

      <InterUIContainer margin="32px 24px 32px">
        <PMedium bold>Pague menos pelo seu seguro</PMedium>
        <PSmall marginBottom="16px" scale={400}>
          Você tem desconto caso a sua empresa já conte com alguma das características de segurança
          listadas.
        </PSmall>
        {buttonCharacteristics}
      </InterUIContainer>

      <InterUISeparator height="8px" margin="0" />

      <InterUIContainer margin="24px 24px 32px">
        <InterUIBox
          data-testid="box24hAssistance"
          align="stretch"
          direction="column"
          actived={isCoverage24Checked()}
        >
          <InterUIList
            text={assistance24h}
            value={
              <InterUISwitch
                disabled={!isBasicCoverageValid()}
                data-testid="switch24Assistance"
                checked={isCoverage24Checked()}
                onChange={() => toggle24Assistance()}
              />
            }
          />
          <PSmall margin="12px 0 0 0" scale={400}>
            Conte com eletricista, encanador, chaveiro, conserto de ar-condicionado, entre outros.
          </PSmall>
          <KnowMore to={TypesRoutes.COVERAGE_DETAILS + CoverageEnum.ASSISTENCIA_24H}>
            Saiba mais
          </KnowMore>
        </InterUIBox>
        <InterUISeparator variant="small" height="1px" margin="24px 0 0" />
        <InterUIListDescription
          margin="24px 0 0"
          withBorder
          data-testid="test-product-manual"
          iconLeft={iconManual}
          iconRight={iconArrow}
          alignAllItems="center"
          onClick={handleClickManual}
        >
          <PSmall marginBottom="0" bold>
            Manual do produto
          </PSmall>
        </InterUIListDescription>
      </InterUIContainer>

      <InterUISeparator height="1px" />

      <QuotationDiscountsBS
        discounts={discounts}
        updateQuoteDiscounts={updateDiscounts}
        selectedDiscountIds={selectedDiscountIds()}
        toggleState={[showDiscountsBS, setShowDiscountsBS]}
      />

      <AdditionalCoveragePriceBS
        toggleState={[showCoveragePriceBS, setShowCoveragePriceBS]}
        basicCoverageValue={basicCoverageValue}
        coverage={coverageToEdit}
        updateCoverage={updateCoverage}
      />
      <MinAdditionalCoveragesBS toggleState={[showMinCoveragesBS, setShowMinCoveragesBS]} />
    </InterUIContainer>
  ) : (
    <ContainerFullPage height="0px" margin="0" overflowHidden>
      <LoadQuotation />
    </ContainerFullPage>
  )
}
