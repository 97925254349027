import { Reducer } from 'redux'

import { NewRelicState, NewRelicTypes } from './types'

const INITIAL_STATE: NewRelicState = {
  context: '',
}

const reducer: Reducer<NewRelicState> = (state = INITIAL_STATE, action) => {
  if (action.type === NewRelicTypes.SET_USER_CONTEXT) {
    return { ...state, context: action.payload }
  }
  return state
}

export default reducer
