import BaseService from '../config/bridge/BaseService'
import { IAddress, ISearchAddress, IUpdateAddressRequest } from '../store/ducks/address/types'

export default class AddressService {
  static async getAddressByZipCode(zipCode: string) {
    return BaseService.doExecute<ISearchAddress>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/address/${zipCode}`,
    })
  }

  static async updateAddress(account: string, quoteId: number, address: IUpdateAddressRequest) {
    const headers = {
      bankAccount: account,
    }

    const data = address as unknown as Record<string, string>

    return BaseService.doExecute<IAddress>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_PROTECTED_COMPANY_V1}/quotes/${quoteId}`,
      data,
      headers,
    })
  }
}
