import styled from 'styled-components'

import { PSmall } from '../../../../styles/commons'

export const NumberContainer = styled.div``

export const NumberGroup = styled.div`
  display: flex;
  margin-top: 16px;
  column-gap: 24px;

  > div {
    flex: 1;
  }

  label {
    align-items: flex-start;
    min-width: 120px;

    div {
      padding-right: 0;
    }
  }
`

export const ErrorMessage = styled(PSmall)`
  text-align: right;
  margin-top: 4px;
  color: ${(props) => `${props.theme.colors.feedback.error.A500}`};
`

export const ContentLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`
