export enum InspectionContactTypes {
  getSelectContact = `@inspectionContact/getSelectContact`,
  setSelectContact = `@inspectionContact/setSelectContact`,
}

export interface IInspectionContact {
  name: string
  phone: string
}

export interface InspectionContactState {
  readonly selectedContact: IInspectionContact
}
