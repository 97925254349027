import { H2, H3, PSmall } from 'src/styles/commons'

import { Box } from './PriceBox.style'

interface IPriceBox {
  price: string
  monthlyPrice: string
}

export const PriceBox = ({ price, monthlyPrice }: IPriceBox) => (
  <Box>
    <PSmall scale={400}>Coberturas iniciais de</PSmall>
    <H2 scale={600}>{price}</H2>
    <PSmall scale={400}>A partir de</PSmall>
    <H3 scale={600}>{monthlyPrice}/mês</H3>
  </Box>
)
