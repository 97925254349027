import { ICoverage } from '../coverages/types'
import { IDiscount } from '../discounts/types'
import { SagaWithHistory } from '../utils/providerSaga'

export enum HireTypes {
  CREATE_HIRE = `@quote/CREATE_HIRE`,
  GET_HIRED_QUOTE_REQUEST = `@quote/GET_HIRED_QUOTE_REQUEST `,
  GET_HIRED_QUOTE = `@quote/GET_HIRED_QUOTE`,
  SET_HIRED_QUOTE = `@quote/SET_HIRED_QUOTE`,
}

export interface IHireQuoteRequest extends SagaWithHistory {
  inspector?: {
    name: string
    phoneNumber: string
  }
}

export interface IHiredQuote {
  id: number
  status: string
  companyName: string
  name: string
  monthlyValue: string
  initialDate: string
  endDate: string
  registryDate: string
  paymentMethod: string
  paymentDate: string
  protocol: string
  cancelDate?: string
  protocolCancel?: string
  Coverages: ICoverage[]
  Discounts: IDiscount[]
  proposalPdf?: string
}

export interface IHireState {
  readonly hiredQuote: IHiredQuote
}
