import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { PMedium } from 'src/styles/commons'
import { Button } from 'src/components'
import { IDiscount } from 'src/store/ducks/discounts/types'

import { InterUIBottomSheet, InterUICheckBox, InterUISeparator } from '@interco/inter-ui-react-lib'
import { uuid } from '@interco/lib-util'

interface DiscountsProps {
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
  discounts: IDiscount[]
  selectedDiscountIds: number[]
  updateQuoteDiscounts: (discounts: IDiscount[]) => void
}

export function QuotationDiscountsBS({
  toggleState,
  discounts,
  selectedDiscountIds,
  updateQuoteDiscounts,
}: DiscountsProps) {
  const [toggleBS, setToggleBS] = toggleState
  const [selectedDiscounts, setSelectedDiscounts] = useState<IDiscount[]>([])

  const resetToOriginalDiscounts = () => {
    const selDiscounts = discounts.filter((disc) => selectedDiscountIds.includes(disc.id))
    setSelectedDiscounts(selDiscounts)
  }

  useEffect(() => {
    resetToOriginalDiscounts()
  }, [selectedDiscountIds])

  const handleCheckboxClick = (item: IDiscount) => {
    if (!selectedDiscounts.includes(item)) {
      selectedDiscounts.push(item)
    } else {
      const index = selectedDiscounts.indexOf(item)
      selectedDiscounts.splice(index, 1)
    }

    setSelectedDiscounts([...selectedDiscounts])
  }

  const discountWasSelected = (item: IDiscount) => (item ? selectedDiscounts.includes(item) : false)

  const closeBottomSheet = () => {
    setToggleBS(false)
    resetToOriginalDiscounts()
  }

  const handleContinue = () => {
    const updatedDiscounts = discounts.map((discount) => {
      if (selectedDiscounts.includes(discount)) {
        discount.selected = true
      } else {
        discount.selected = false
      }

      return discount
    })

    updateQuoteDiscounts(updatedDiscounts)
    setToggleBS(false)
  }

  return (
    <InterUIBottomSheet
      data-testid="characteristicsBS"
      title="Marque se sua empresa já conta com:"
      toggle={toggleBS}
      onHide={closeBottomSheet}
    >
      {discounts.map((discount) => (
        <React.Fragment key={uuid()}>
          <InterUICheckBox
            key={discount.id}
            value={discount.id}
            onChange={() => {
              handleCheckboxClick(discount)
            }}
            checked={discountWasSelected(discount)}
          >
            <PMedium>{discount.description}</PMedium>
          </InterUICheckBox>

          <InterUISeparator height="1px" margin="10px 0 32px" />
        </React.Fragment>
      ))}
      <Button
        data-testid="button-continue-CharacteristicsBS"
        margin="52px 0 0"
        variant="primary"
        onClick={handleContinue}
      >
        <PMedium marginBottom="0">Continuar</PMedium>
      </Button>
    </InterUIBottomSheet>
  )
}
