import { Reducer } from 'redux'

import { CoveragesState, CoveragesTypes } from './types'

const INITIAL_STATE: CoveragesState = {
  basicCoverageDetail: '',
  coverages: [],
  simulationCoverages: [],
}

const reducer: Reducer<CoveragesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CoveragesTypes.SET_BASIC_COVERAGE_DETAILS:
      return { ...state, basicCoverageDetail: action.payload }
    case CoveragesTypes.SET_COVERAGES:
      return { ...state, coverages: action.payload }
    case CoveragesTypes.SET_SIMULATION_COVERAGES:
      return { ...state, simulationCoverages: action.payload }

    default:
      return state
  }
}
export default reducer
