export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.replace('data:application/pdf;base64,', '')

  const bytes = atob(base64WithoutPrefix)
  let { length } = bytes
  const out = new Uint8Array(length)

  while (length) {
    out[length] = bytes.charCodeAt(length)
    length -= 1
  }

  return URL.createObjectURL(new Blob([out], { type: 'application/pdf' }))
}
