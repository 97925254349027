import { InterUIContainer, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export function LoadProfessionalActivities() {
  return (
    <SkeletonContent data-testid="loading-home">
      <InterUIContainer margin="0">
        <InterUIContainer margin="52px 24px 24px">
          <SkeletonContent>
            <InterUISkeleton height="44px" marginBottom="24px" />
            <InterUISkeleton height="52px" marginBottom="28px" />
          </SkeletonContent>

          <SkeletonContent>
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="32px" height="32px" marginBottom="0" />
            </SkeletonGrid>
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="32px" height="32px" marginBottom="0" />
            </SkeletonGrid>
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="32px" height="32px" marginBottom="0" />
            </SkeletonGrid>
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="32px" height="32px" marginBottom="0" />
            </SkeletonGrid>
            <SkeletonGrid>
              <InterUISkeleton height="40px" marginBottom="0" style={{ flex: 1 }} />
              <InterUISkeleton width="32px" height="32px" marginBottom="0" />
            </SkeletonGrid>
          </SkeletonContent>
        </InterUIContainer>

        <InterUIContainer margin="24px">
          <SkeletonContent>
            <InterUISkeleton height="72px" marginBottom="44px" />
          </SkeletonContent>
        </InterUIContainer>
      </InterUIContainer>
    </SkeletonContent>
  )
}
