import { Dispatch, SetStateAction } from 'react'

import BaseBridge from 'src/config/bridge/BaseBridge'
import { AfterSalesEnum } from 'src/enums/AfterSalesEnum'
import { PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { CONTACT_PHONE } from 'src/utils/constants'
import { ITagAfterSales } from 'src/pages/afterSales/AfterSalesStatus/AfterSalesStatus'

import { InterUIBottomSheet, InterUIListDescription } from '@interco/inter-ui-react-lib'

import { Button } from '../Button/Button'

interface AfterSalesBSProps {
  status: string
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
  handleClick: () => void
  handleTag: ({ content_action }: ITagAfterSales) => void
}
interface IDataBS {
  title: string
  description: JSX.Element
  nameButton: string
  redirect: boolean
}

export const AfterSalesBS = ({
  status,
  toggleState,
  handleClick,
  handleTag,
}: AfterSalesBSProps) => {
  const [toggle, setToggle] = toggleState
  const theme = useTheme()

  const iconPhone = (
    <Icons.Phone width={16} height={16} color={theme.colors.neutral.grayscale.A500} />
  )

  const analysis: IDataBS = {
    title: 'Contratação em análise',
    description: (
      <>
        <PMedium marginBottom="24px">
          Enquanto isso, a sua empresa já está protegida e conta com as coberturas solicitadas. Em
          até 15 dias você terá o retorno da solicitação pelo app e no seu e-mail.
        </PMedium>
        <PMedium marginBottom="24px">Se tiver dúvidas, entre em contato.</PMedium>
        <InterUIListDescription
          iconLeft={iconPhone}
          onClick={() => BaseBridge.callPhoneNumber(CONTACT_PHONE)}
        >
          <PMedium fontWeight="700" bold color={theme.colors.primary.A500} marginBottom="0">
            0800 770 1318
          </PMedium>
          <PMedium>Capitais, regiões metropolitanas e demais localidades</PMedium>
        </InterUIListDescription>
        <PMedium margin="16px 0 24px ">Atendimento todos os dias, 24h.</PMedium>
      </>
    ),
    nameButton: 'Entendi',
    redirect: false,
  }

  const hireAgain: IDataBS = {
    title: 'Seguro não aprovado',
    description: (
      <PMedium marginBottom="24px" scale={400}>
        Seu Seguro Empresa Protegida não foi aprovado. Entre em contato com a Liberty ou realize uma
        nova cotação.
      </PMedium>
    ),
    nameButton: 'Realizar nova cotação',
    redirect: true,
  }

  const renew: IDataBS = {
    title: 'Seguro cancelado',
    description: (
      <PMedium marginBottom="24px" scale={400}>
        O período de vigência do seu seguro terminou, mas não se preocupe, você pode contratar outra
        vez pelo app.
      </PMedium>
    ),
    nameButton: 'Contratar novamente',
    redirect: true,
  }

  const finished: IDataBS = {
    title: 'Seguro finalizado',
    description: (
      <PMedium marginBottom="24px" scale={400}>
        O período de vigência do seu seguro terminou, mas não se preocupe, você pode contratar outra
        vez pelo app.
      </PMedium>
    ),
    nameButton: 'Contratar novamente',
    redirect: true,
  }

  const chooseStatus: { [status: string]: IDataBS } = {
    [AfterSalesEnum.IN_ANALYSIS]: analysis,
    [AfterSalesEnum.NOT_APPROVED]: hireAgain,
    [AfterSalesEnum.CANCELED]: renew,
    [AfterSalesEnum.FINISHED]: finished,
  }

  const handleActiveSubscription = (redirect: boolean) => {
    handleTag({ content_id: chooseStatus[status].nameButton, content_action: 'clique' })
    setToggle(false)
    if (redirect) handleClick()
  }

  return (
    <InterUIBottomSheet
      data-testid="active-bottom-sheet"
      toggle={toggle}
      onHide={() => setToggle(false)}
      title={chooseStatus[status].title}
    >
      {chooseStatus[status].description}

      <Button
        variant="primary"
        onClick={() => handleActiveSubscription(chooseStatus[status].redirect)}
      >
        {chooseStatus[status].nameButton}
      </Button>
    </InterUIBottomSheet>
  )
}
